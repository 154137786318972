.ranking{
    &__section{
        padding: 25px 0 80px;
        @include media-breakpoint-down(md){
            padding-bottom: 0;
        }
        &__header{
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 30px;
            @include media-breakpoint-down(md){
                margin: 0 -15px 15px;
                padding: 0 15px 10px;
            }
        }
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
        }
        &__sort{
            text-align: center;
            margin-bottom: 30px;
        }
    }
    &__container{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -19px;
        @media (max-width: 1104px){
            margin: 0;
        }
        &:after{
            visibility: hidden;
            opacity: 0;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            background-image: url(../images/ajax-loader.gif);
            background-repeat: no-repeat;
            background-position: center;
            z-index: 11;
        }
        &.loading{
            &:after{
                opacity: 1;
                visibility: visible;
            }
        }
        &.loaded{
            &:after{
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
            }
        }
        &.error{
            position: relative;
            &:before{
                position: absolute;
                content: "情報が取得できませんでした。";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: 1;
                z-index: 11;
                font-size: 15px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    &__item{
        width: 33.33333%;
        padding: 0 19px;
        margin-bottom: 40px;
        border-right: 1px solid #C3C3C3;
        &:nth-child(3n){
            border-right: none;
        }
        @media (max-width: 1478px){
            width: 50%;
            &:nth-child(3n){
                border-right: 1px solid #C3C3C3;
            }
            &:nth-child(2n){
                border-right: none;
            }
        }
        @media (max-width: 1082px){
            border-bottom: 1px solid #C3C3C3;
            width: 100%;
            border-right: none;
            padding: 0 0 20px;
            margin-bottom: 20px;
            &:nth-child(3n){
                border-right: none;
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }
    &__btn{
        font-size: 14px;
        font-weight: bold;
        display: block;
        width: 100%;
        max-width: 165px;
        margin: 0 auto;
        text-align: center;
        padding: 5px;
        border: 1px solid #C3C3C3;
        border-radius: 17px;
        background: linear-gradient(to bottom, #FFFFFF, #DDDDDD);
    }
    &__error{
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        margin: 40px 0 80px;
    }
}
