.modal{
    &-overlay{
        visibility: hidden;
        opacity: 0;
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, .7);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .3s, visibility .3s;
        &.modal-open{
            opacity: 1;
            visibility: visible;
        }
        &.fade-enter-active,
        &.fade-leave-active {
          transition: opacity 0.2s ease-out;
        //   transform: translate3d(0, 0, 0);
        }

        &.fade-enter-from,
        &.fade-leave-to {
        //   transform: translate3d(0, -100%, 0);
            opacity: 0;
        }
    }
    &-container{
        height: 100%;
        overflow-y: auto;
        width: 100%;
        position: relative;
        padding: 90px;
        text-align: center;
        @include media-breakpoint-down(md){
            padding: 40px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display:none;
            }
        }
    }
    &-close{
        position: absolute;
        top: 15px;
        right: 30px;
        width: 60px;
        height: 60px;
        z-index: 1;
        @include media-breakpoint-down(md){
            width: 30px;
            height: 30px;
            top: 5px;
            right: 5px;
        }
        &:before{
            content: "";
            position: absolute;
            width: 60px;
            height: 5px;
            background: #fff;
            top: 28px;
            left: 0;
            transform: rotate(45deg);
            @include media-breakpoint-down(md){
                width: 30px;
                height: 3px;
                top: 14px;
            }
        }
        &:after{
            content: "";
            position: absolute;
            width: 60px;
            height: 5px;
            background: #fff;
            top: 28px;
            left: 0;
            transform: rotate(-45deg);
            @include media-breakpoint-down(md){
                width: 30px;
                height: 3px;
                top: 14px;
            }
        }
    }
    max-width: 100%;
    display: inline-block;
    margin: 0 auto;
}
.modal-gallery{
    &__item{
        margin-bottom: 60px;
        @include media-breakpoint-down(md){
            margin-bottom: 20px;
        }
    }
}
.modal-movie{
    width: 960px;
    max-width: 100%;
    @include media-breakpoint-down(md){
        margin: 0 -40px;
        max-width: calc(100% + 80px);
    }
    &__frame{
        position: relative;
        padding-top: 56.25%;
        margin-bottom: 25px;
        width: 100%;
        iframe, video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__ttl{
        color: #fff;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            padding: 0 15px;
        }
    }
    &__txt{
        color: #fff;
        text-align: left;
        @include media-breakpoint-down(md){
            padding: 0 15px;
        }
    }
}
.modal-image{
    width: 960px;
    max-width: 100%;
    @include media-breakpoint-down(md){
        margin: 0 -40px;
        max-width: calc(100% + 80px);
    }
    &__frame{
        margin-bottom: 25px;
    }
    &__ttl{
        color: #fff;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            padding: 0 15px;
        }
    }
    &__txt{
        color: #fff;
        text-align: left;
        @include media-breakpoint-down(md){
            padding: 0 15px;
        }
    }
}

.modal-menu-myfolder-overlay, .modal-menu-favorite-overlay{
    .modal{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.modal-folder{
    &-overlay{
        .modal{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    background: #fff;;
    padding: 25px;
    width: 680px;
    max-width: 100%;
    height: 400px;
    max-height: 100%;
    overflow-y: auto;
    text-align: left;
    @include media-breakpoint-down(sm){
        padding: 25px;
    }
    &__ttl{
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #707070;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }
    &__mkdir{
        display: flex;
        margin: 0 -6px 25px;
        @include media-breakpoint-down(sm){
            display: block;
        }
        &__item{
            &:first-child{
                width: 60%;
                padding: 0 6px;
                @include media-breakpoint-down(sm){
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            &:last-child{
                width: 40%;
                padding: 0 6px;
                @include media-breakpoint-down(sm){
                    width: 100%;
                }
            }
        }
        &__name{
            width: 100%;
            display: block;
            border: 1px solid #707070;
            height: 100%;
            font-size: 20px;
            padding: 5px 10px;
            @include media-breakpoint-down(sm){
                font-size: 16px;
            }
        }
        &__btn{
            width: 100%;
            border: 1px solid #C09C58;
            border-radius: 3px;
            height: 100%;
            font-size: 15px;
            font-weight: bold;
            display: block;
            text-align: center;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            padding: 8px;
            @include media-breakpoint-down(md){
                font-size: 13px;
            }
        }
        &__list{
            border-top: 1px dashed #707070;
            font-size: 20px;
            @include media-breakpoint-down(sm){
                font-size: 16px;
            }
            li{
                border-bottom: 1px dashed #707070;
                padding: 11px 10px;
                &.uncategorized{
                    position: relative;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #F0F0F0;
                        opacity: .69;
                    }
                }
                a{
                    padding-left: 40px;
                    background-image: url(../images/icon-folder.svg);
                    background-repeat: no-repeat;
                    background-size: 30px;
                    display: inline-block;
                    @include media-breakpoint-down(sm){
                        padding-left: 30px;
                        background-size: 25px;
                    }
                }
                span{
                    padding-left: 40px;
                    background-image: url(../images/icon-folder.svg);
                    background-repeat: no-repeat;
                    background-size: 30px;
                    display: inline-block;
                    @include media-breakpoint-down(sm){
                        padding-left: 30px;
                        background-size: 25px;
                    }
                }
            }
        }
    }
}

.modal-audio{
    border: 1px solid #fff;
    &-overlay{
        position: fixed;
        z-index: 9999;
        right: 0;
        bottom: 0;
        background: rgba(#000, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(101%);
        transition: transform .3s;
        @include media-breakpoint-down(md){
            top: 0;
            left: 0;
            transition: opacity .3s, visibility .3s;
            opacity: 0;
            visibility: hidden;
            transform: translateX(0);
        }
        &.modal-open{
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            @include media-breakpoint-down(md){
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-container{
        height: 100%;
        overflow-y: auto;
        width: 100%;
        position: relative;
        padding: 60px;
        text-align: center;
        @include media-breakpoint-down(md){
            padding: 40px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display:none;
            }
        }
    }
    &-close{
        position: absolute;
        top: 10px;
        left: 10px;
        right: auto;
        width: 40px;
        height: 40px;
        z-index: 1;
        cursor: pointer;
        @include media-breakpoint-down(md){
            width: 30px;
            height: 30px;
            top: 5px;
            left: auto;
            right: 5px;
        }
        &:before{
            content: "";
            position: absolute;
            width: 40px;
            height: 5px;
            background: #fff;
            top: 18px;
            left: 0;
            transform: rotate(45deg);
            @include media-breakpoint-down(md){
                width: 30px;
                height: 3px;
                top: 14px;
            }
        }
        &:after{
            content: "";
            position: absolute;
            width: 40px;
            height: 5px;
            background: #fff;
            top: 18px;
            left: 0;
            transform: rotate(-45deg);
            @include media-breakpoint-down(md){
                width: 30px;
                height: 3px;
                top: 14px;
            }
        }
    }
    max-width: 100%;
    display: inline-block;
    margin: 0 auto;
}
