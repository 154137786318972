.sort{
    overflow-x: auto;
    margin: 0 -15px;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar{
        display:none;
    }
    &--center{
        text-align: center;
        margin-bottom: 40px;
    }
    &__list{
        display: inline-flex;
        padding: 0 15px;
    }
    &__item{
        border-right: 1px solid #C3C3C3;
        overflow: hidden;
        &:first-child{
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        &:last-child{
            border-right: none;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
    &__link{
        display: block;
        font-weight: bold;
        background: #E9EAEE;
        padding: 10px 30px;
        white-space: nowrap;
        border: none;
        &--active{
            background: #0067B2;
            color: #fff;
            &:hover{
                color: #fff;
            }
        }
    }
}
