.main{
    width: calc(100% - 227px);
    padding-bottom: 40px;
    opacity: 1;
    transition: opacity .3s;
    @include media-breakpoint-down(md){
        width: 100%;
    }
    &[v-cloak]{
        opacity: 0;
    }
    &__nav{
        border-bottom: 1px solid #C3C3C3;
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(md){
            display: block;
            border-bottom: none;
        }
        &__category{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
            padding: 5px 13px;
            border-right: 1px solid #C3C3C3;
            white-space: nowrap;
            @include media-breakpoint-down(md){
                display: inline-block;
            }
        }
        &__breadcrumb{
            font-size: 11px;
            padding: 7px 20px;
            @include media-breakpoint-down(md){
                border-top: 1px solid #C3C3C3;
                padding: 7px 15px;
                text-align: right;
            }
            li{
                display: inline-block;
                margin-right: 18px;
                position: relative;
                &:after{
                    content: "＞";
                    position: absolute;
                    top: 0;
                    right: -16px;
                }
                &:last-child{
                    margin-right: 0;
                    &:after{
                        content: none;
                    }
                }
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        &__name{
            margin-bottom: 0;
            padding: 6px 15px;
            @include media-breakpoint-down(md){
                border-top: 1px solid #C3C3C3;
                text-align: right;
            }
        }
    }
    &__top{
        padding-top: 30px;
        margin-bottom: 45px;
        overflow: hidden;
        @include media-breakpoint-down(md){
            padding-top: 0;
            margin-bottom: 30px;
        }
        &__container{
            padding: 0 40px;
            max-width: 1115px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            @include media-breakpoint-down(xxl){
                max-width: 906px;
            }
            @include media-breakpoint-down(xl){
                max-width: 697px;
            }
            @include media-breakpoint-down(lg){
                max-width: 488px;
            }
        }
    }
    &__slider{
        max-width: 100%;
        width: 502px;
        margin-bottom: 20px;
        overflow: visible !important;
        @include media-breakpoint-down(sm){
            margin-bottom: 40px;
        }
        &-container{
            position: relative;
            &:hover{
                .main__slider__pagination, .main__slider__button-next, .main__slider__button-prev{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &__slide{
            border: 1px solid #000;
            @include media-breakpoint-down(sm){
                border: none;
            }
            &:hover{
                border-color: #DB002F;
            }
        }
        &__pagination{
            bottom: 0 !important;
            background: linear-gradient(to bottom, transparent, rgba(#000, .8));
            padding: 15px 0 7px;
            height: 29px;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s !important;
            @include media-breakpoint-down(sm){
                opacity: 1;
                visibility: visible;
                bottom: -27px !important;
                background: none;
            }
            .swiper-pagination-bullet{
                width: 7px;
                height: 7px;
                opacity: 1;
                background: #fff;
                margin: 0 4px !important;
                vertical-align: top;
                @include media-breakpoint-down(sm){
                    background: #C3C3C3;
                }
                &-active{
                    background: #DB002F;
                }
            }
        }
        &__button-next{
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s !important;
            &.swiper-button-next{
                right: 5px;
                width: 40px;
                height: 40px;
                margin-top: -20px;
                background: rgba(#000, .7);
                border-radius: 2px;
                @include media-breakpoint-down(sm){
                    display: none;
                }
                &:focus{
                    outline: none;
                }
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-style: solid;
                    border-width: 3px 3px 0 0;
                    border-color: #DB002F;
                    top: 50%;
                    right: 0;
                    transform: rotate(45deg);
                    margin-left: -8px;
                }
                &.swiper-button-disabled{
                    opacity: 1;
                    &:after{
                        border-color: #C3C3C3;
                    }
                }
            }
        }
        &__button-prev{
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s !important;
            &.swiper-button-prev{
                left: 5px;
                width: 40px;
                height: 40px;
                margin-top: -20px;
                background: rgba(#000, .7);
                border-radius: 2px;
                @include media-breakpoint-down(sm){
                    display: none;
                }
                &:focus{
                    outline: none;
                }
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-style: solid;
                    border-width: 3px 0 0 3px;
                    border-color: #DB002F;
                    top: 50%;
                    right: 0;
                    transform: rotate(-45deg);
                    margin-right: -8px;
                }
                &.swiper-button-disabled{
                    opacity: 1;
                    &:after{
                        border-color: #C3C3C3;
                    }
                }
            }
        }
    }
    &__sub-slider{
        width: 100%;
        &__button-next{
            &.swiper-button-next{
                right: 5px;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                &:focus{
                    outline: none;
                }
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-style: solid;
                    border-width: 3px 3px 0 0;
                    border-color: #DB002F;
                    top: 50%;
                    right: 0;
                    transform: rotate(45deg);
                    margin-left: -5px;
                }
                &.swiper-button-disabled{
                    opacity: 1;
                    &:after{
                        border-color: #C3C3C3;
                    }
                }
            }
        }
        &__button-prev{
            &.swiper-button-prev{
                left: 5px;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                &:focus{
                    outline: none;
                }
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-style: solid;
                    border-width: 3px 0 0 3px;
                    border-color: #DB002F;
                    top: 50%;
                    right: 0;
                    transform: rotate(-45deg);
                    margin-right: -5px;
                }
                &.swiper-button-disabled{
                    opacity: 1;
                    &:after{
                        border-color: #C3C3C3;
                    }
                }
            }
        }
    }
    &__container{
        padding: 0 30px;
        overflow: hidden;
        @include media-breakpoint-down(lg){
            padding: 0 15px;
        }
        &--mypage{
            padding-top: 30px;
            padding-bottom: 60px;
            font-size: 16px;
            @include media-breakpoint-down(md){
                padding-bottom: 0;
            }
        }
    }
    &__section{
        margin-bottom: 70px;
        @include media-breakpoint-down(md){
            margin-bottom: 40px;
        }
        &:last-of-type{
            @include media-breakpoint-down(md){
                margin-bottom: 0;
            }
        }
        &__header{
            border-bottom: 1px solid #000;
            @include clearfix();
            padding-bottom: 10px;
            margin-bottom: 30px;
            @include media-breakpoint-down(md){
                margin: 0 -15px 15px;
                padding: 0 15px 10px;
            }
            &--rank{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                &:after{
                    content: none;
                }
            }
        }
        &__ttl{
            float: left;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
            &--rank{
                float: none;
                padding-left: 38px;
                padding-top: 8px;
                padding-bottom: 8px;
                background-image: url(../images/ttl-icon-rank.svg);
                background-repeat: no-repeat;
                background-position: left center;
                margin-right: 20px;
            }
        }
        &__link{
            float: right;
            color: #0067B2;
            margin-top: 5px;
            &:hover{
                text-decoration: underline;
                color: #0067B2;
            }
        }
        &__sort{
            @include media-breakpoint-down(xl){

            }
        }
        &__block{
            margin-bottom: 50px;
            @include media-breakpoint-down(md){
                margin-bottom: 35px;
            }
            &:last-of-type{
                @include media-breakpoint-down(md){
                    margin-bottom: 0;
                }
            }
            &__header{
                margin-bottom: 15px;
            }
            &__ttl{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
                display: inline-block;
                padding-left: 35px;
                padding-right: 10px;
                &--video{
                    background-image: url(../images/cat-icon-video.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
                &--game{
                    background-image: url(../images/cat-icon-game.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
                &--e-book{
                    background-image: url(../images/cat-icon-e-book.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
                &--mail-order{
                    background-image: url(../images/cat-icon-mail-order.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
                &--doujin{
                    background-image: url(../images/cat-icon-doujin.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
            }
            &__link{
                color: #0067B2;
            }
        }
        &__bottom{
            margin-top: 30px;
        }
    }
}
