.slider1, .slider1--wide{
    &-container{
        padding: 0 40px;
        position: relative;
        // min-height: 352px;
        @include media-breakpoint-down(md){
            // min-height: 45vw;
        }
        @include media-breakpoint-down(sm){
            padding: 0;
        }
    }
    &.swiper{
        width: 100%;
        @include media-breakpoint-down(sm){
            overflow: visible;
        }
    }
    &__slide{
        &.swiper-slide{
            @include media-breakpoint-down(sm){
                width: 35%;
            }
        }
    }
    &__button-next{
        &.swiper-button-next{
            right: 5px;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            @include media-breakpoint-down(sm){
                display: none;
            }
            &:after{
                content: "";
                width: 16px;
                height: 16px;
                border-style: solid;
                border-width: 3px 3px 0 0;
                border-color: #DB002F;
                top: 50%;
                right: 0;
                transform: rotate(45deg);
                margin-left: -5px;
            }
            &:focus-visible{
                outline: none;
            }
            &.swiper-button-disabled{
                opacity: 1;
                &:after{
                    border-color: #C3C3C3;
                }
            }
        }
    }
    &__button-prev{
        &.swiper-button-prev{
            left: 5px;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            @include media-breakpoint-down(sm){
                display: none;
            }
            &:after{
                content: "";
                width: 16px;
                height: 16px;
                border-style: solid;
                border-width: 3px 0 0 3px;
                border-color: #DB002F;
                top: 50%;
                right: 0;
                transform: rotate(-45deg);
                margin-right: -5px;
            }
            &.swiper-button-disabled{
                opacity: 1;
                &:after{
                    border-color: #C3C3C3;
                }
            }
        }
    }
}

.slider2{
    @extend .slider1;
    &__wrapper{
        &.swiper-wrapper{
            @include media-breakpoint-down(sm){
                box-sizing: border-box;
                flex-wrap: wrap;
                margin: 0;
            }
        }
    }
    &-container{
        @extend .slider1-container;
        @include media-breakpoint-down(sm){
            margin: 0 -5px;
        }
        &:after{
            visibility: hidden;
            opacity: 0;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            background-image: url(../images/ajax-loader.gif);
            background-repeat: no-repeat;
            background-position: center;
            z-index: 11;
        }
    }
    &__slide{
        @extend .slider1__slide;
        &.swiper-slide{
            @include media-breakpoint-down(sm){
                width: 33.33333%;
                padding: 0 5px;
            }
        }
        &--next{
            cursor: pointer;
            align-self: center;
        }
    }
    &__button-next{
        @extend .slider1__button-next;
    }
    &__button-prev{
        @extend .slider1__button-prev;
    }
}

.slider3{
    @extend .slider2;
    &__wrapper{
        @extend .slider2__wrapper;
    }
    &-container{
        @extend .slider2-container;
    }
    &__slide{
        @extend .slider2__slide;
        &.swiper-slide{
            @include media-breakpoint-down(sm){
                width: 50%;
                margin-bottom: 10px;
            }
        }
        &--next{
            @extend .slider2__slide--next;
        }
    }
    &__button-next{
        @extend .slider2__button-next;
    }
    &__button-prev{
        @extend .slider2__button-prev;
    }
}

.slider4{
    @extend .slider3;
    &__wrapper{
        @extend .slider3__wrapper;
    }
    &-container{
        @extend .slider3-container;
    }
    &__slide{
        @extend .slider3__slide;
        &--next{
            @extend .slider3__slide--next;
        }
    }
    &__button-next{
        @extend .slider3__button-next;
    }
    &__button-prev{
        @extend .slider3__button-prev;
    }
}

.slider5{
    &.swiper{
        overflow: visible;
        padding-bottom: 40px;
        // @media (max-width: 1082px){
        //     overflow: hidden;
        // }
    }
    &__wrapper{

    }
    &-container{
        @extend .slider4-container;
        margin: 0 -30px;
        padding: 0 120px;
        position: relative;
        @media (max-width: 1202px){
            padding: 0 60px;
        }
        @media (max-width: 1082px){
            padding: 0 120px 0 80px;
        }
        @include media-breakpoint-down(sm){
            padding: 0 25%;
        }
    }
    &__slide{
        position: relative;
        &:after{
            position: absolute;
            content: "";
            top: 0;
            right: -20px;
            width: 1px;
            height: 100%;
            background: #C3C3C3;
        }
        &:last-child{
            &:after{
                content: none;
            }
        }
    }
    &__pagination{
        &.swiper-pagination{
            line-height: 1;
            bottom: 0 !important;
            .swiper-pagination-bullet{
                margin: 0 6px !important;
                width: 10px;
                height: 10px;
                background: #C3C3C3;
                opacity: 1;
            }
            .swiper-pagination-bullet-active{
                background: #DB002F;
            }
        }
    }
}

.slider2-container.loading, .slider4-container.loading, .slider5-container.loading{
    &:after{
        opacity: 1;
        visibility: visible;
    }
}
.slider2-container.loaded, .slider4-container.loaded, .slider5-container.loaded{
    &:after{
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
    }
}
.error.slider2-container, .error.slider4-container, .error.slider5-container{
    position: relative;
    &:before{
        position: absolute;
        content: "情報が取得できませんでした。";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 1;
        z-index: 11;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
