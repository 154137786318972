.product{
    display: block;
    max-width: 162px;
    padding: 3px;
    margin: 0 auto;
    @include media-breakpoint-down(sm){
        padding: 0;
        max-width: 156px;
    }
    &:hover{
        // .product{
        //     &__name{
        //         text-decoration: underline;
        //     }
        //     &__img{
        //         opacity: .8;
        //         @include media-breakpoint-down(sm){
        //             opacity: 1;
        //         }
        //     }
        // }
    }
    &--wide1{
        max-width: 202px;
        @include media-breakpoint-down(sm){
            max-width: 196px;
        }
    }
    &--wide2{
        max-width: 258px;
        @include media-breakpoint-down(sm){
            max-width: 252px;
        }
    }
    &--next{
        text-align: center;
        display: block;
        img{
            margin-bottom: 5px;
        }
        p{
            text-align: center;
            margin-bottom: 0;
        }
    }
    &--row{
        display: flex;
        max-width: 358px;
        .product{
            &__img{
                width: 156px;
                max-width: 44.4%;
                margin-bottom: 0;
            }
            &__main{
                width: 196px;
                max-width: 55.6%;
                padding-left: 15px;
                margin-top: 0;
                @include media-breakpoint-down(sm){
                    display: block;
                }
            }
        }
        &__rank{
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 5px;
            border-bottom: 1px solid #000;
            margin-bottom: 10px;
            &--1{
                padding-left: 30px;
                background-image: url(../images/ranking/prod-rank1.svg);
                background-repeat: no-repeat;
                background-position: left bottom 10px;
            }
            &--2{
                padding-left: 30px;
                background-image: url(../images/ranking/prod-rank2.svg);
                background-repeat: no-repeat;
                background-position: left bottom 10px;
            }
            &--3{
                padding-left: 30px;
                background-image: url(../images/ranking/prod-rank3.svg);
                background-repeat: no-repeat;
                background-position: left bottom 10px;
            }
        }
    }
    &--mobile-row{
        max-width: none;
        padding: 0;
        .product{
            &__name{
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            &__img{
                max-width: 186px;
                margin: 0 auto 15px;
                padding: 15px 15px 0;
                @include media-breakpoint-down(sm){
                    padding: 0;
                }
                a{
                    &:hover{
                        opacity: 0.8;
                    }
                    img{

                    }
                }
            }
            &__main{
                max-width: 186px;
                margin: 0 auto;
                padding: 15px;
                @include media-breakpoint-down(sm){
                    padding: 0;
                }
            }
        }
        &:hover{
            .product{
                &__name{
                    // text-decoration: none;
                }
                &__img{
                    opacity: 1;
                }
            }
        }
        @include media-breakpoint-down(sm){
            display: flex;
            max-width: 352px;
            .product{
                &__img{
                    width: 156px;
                    max-width: 44.4%;
                    margin-bottom: 0;
                }
                &__main{
                    width: 196px;
                    max-width: 55.6%;
                    padding-left: 15px;
                    display: block;
                }
            }
        }
    }
    &--doujin-row{
        padding: 0;
        max-width: 340px;
        display: flex;
        @include media-breakpoint-down(sm){
            max-width: auto;
            display: block;
        }
        .product{
            &__rank{
                min-height: auto;
                text-align: left;
                display: block;
                width: 47px;
                margin-bottom: 0;
                @include media-breakpoint-down(sm){
                    display: flex;
                    width: auto;
                    margin-bottom: 8px;
                    min-height: 31px;
                }
                span{
                    margin-left: 15px;
                    @include media-breakpoint-down(sm){
                        margin-left: 0;
                    }
                }
            }
            &__img{
                width: 137px;
                margin-bottom: 0;
                @include media-breakpoint-down(sm){
                    width: auto;
                }
            }
            &__main{
                width: 156px;
                padding-left: 13px;
            }
        }
    }
    &--article{
        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
        }
    }
    &--new{
        .product{
            &__img{
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 156px;
                max-width: 156px;
                padding: 0;
                margin: 15px auto;
                aspect-ratio: 1/1;
                @include media-breakpoint-down(sm){
                    display: block;
                    min-height: 0;
                    margin: 0;
                }
                
            }
            &__tags{
                max-width: 190px;
                margin: 0 auto 10px;
                padding: 0 15px;
                @include media-breakpoint-down(sm){
                    margin: 0 -2px 5px;
                    padding: 0;
                }
            }
            &__main{
                padding-top: 10px;
                @include media-breakpoint-down(sm){
                    padding-top: 0;
                    width: auto;
                    max-width: none;
                    padding-left: 0;
                    display: block;
                }
            }
            &__inner{
                @include media-breakpoint-down(sm){
                    width: 196px;
                    max-width: 55.6%;
                    padding-left: 15px;
                    display: block;
                }
            }
        }
    }
    &__rank{
        text-align: center;
        margin-bottom: 8px;
        min-height: 31px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        span{
            font-size: 18px;
            line-height: 1.2;
            font-weight: bold;
            vertical-align: bottom;
        }
    }
    &__img{
        display: block;
        position: relative;
        margin: 0;
        &:hover{
            opacity: .8;
            @include media-breakpoint-down(sm){
                opacity: 1;
            }
        }
        // img{
        //     width: 100%;
        // }
        &__band{
            position: absolute;
            top: -3px;
            left: -3px;
            @include media-breakpoint-down(sm){
                display: none;
            }
        }
        &__play{
            position: absolute;
            left: 0;
            bottom: 0;
            // @include media-breakpoint-down(sm){
            //     display: none;
            // }
        }
        &__frame{
            overflow: hidden;
            position: relative;
            display: block;
        }
        &__bg{
            width: 100%;
        }
        &__main{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__category{
        display: block;
        padding: 2px;
        font-size: 13px;
        background: #DB002F;
        color: #fff;
        font-weight: bold;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        &--link{
            &:hover{
                opacity: 0.8;
                color: #fff;
            }
        }
        &--blue{
            background: $blue-400;
        }
        &--indigo{
            background: $indigo-300;
        }
        &--purple{
            background: $purple-400;
        }
        &--pink{
            background: $pink-400;
        }
        &--red{
            background: $red-400;
        }
        &--orange{
            background: $orange-400;
        }
        &--yellow{
            background: $yellow-400;
        }
        &--green{
            background: $green-400;
        }
        &--teal{
            background: $teal-400;
        }
        &--cyan{
            background: $cyan-400;
        }
        &--gray{
            background: $gray-500;
        }
    }
    &__hr{
        border-top: 1px dashed #C3C3C3;
        margin: 0;
        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    &__main{
        margin-top: 10px;
        font-size: 13px;
    }
    &__tags{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -2px 5px;
        li{
            padding: 1px 3px;
            margin: 1px 2px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            background: #7E7E7E;
            border-radius: 3px;
        }
    }
    &__name{
        color: #004CDB;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 39px;
        &:hover{
            color: #004CDB;
            text-decoration: underline;
        }
    }
    &__company{
        color: #0067B2;
        font-size: 11px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        &:hover{
            color: #0067B2;
            text-decoration: underline;
        }
    }
    &__price{
        margin-bottom: 10px;
        &__main{
            display: inline-block;
            color: #DB002F;
            font-weight: bold;
            margin-right: 5px;
            &--stockout{
                color: #939393;
            }
        }
        &__original{
            display: inline-block;
            text-decoration: line-through;
        }
        &__stockout{
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
            color: #fff;
            padding: 0 3px;
            background: #DB002F;
        }
    }
    &__discount{
        color: #fff;
        font-weight: bold;
        text-align: center;
        background: #FD5A29;
        margin-bottom: 0;
        padding: 2px;
        border-radius: 1em;
        position: relative;
        word-wrap: break-word;
        &:before{
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            @include triangle(5px, 3px, #FD5A29, bottom);
        }
    }
    &__btn{
        margin-top: 10px;
        padding: 3px;
        display: block;
        width: 100%;
        color: #fff;
        text-align: center;
        background: #0067B2;
        font-weight: bold;
        border-radius: 3px;
        &:hover{
            color: #fff;
        }
        &.inactive{
            pointer-events: none;
            background: #E9EAEE;
        }
        &--video{
            span{
                position: relative;
                padding-left: 12px;
                &:before{
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 8px;
                    height: 9px;
                    background-image: url(../images/icon-play_white.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        &--audio{
            span{
                position: relative;
                padding-left: 15px;
                &:before{
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/icon-audio.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        &--book{
            span{
                position: relative;
                padding-left: 14px;
                &:before{
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 8px;
                    height: 11px;
                    background-image: url(../images/icon-book_white.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        &--download{
            span{
                position: relative;
                padding-left: 14px;
                &:before{
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 11px;
                    height: 12px;
                    background-image: url(../images/icon-dl.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        &--detail{
            span{
                position: relative;
                padding-left: 15px;
                &:before{
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background-image: url(../images/icon-glass.svg);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}
