.genre{
    &__section{
        margin: 30px 0;
        @include media-breakpoint-down(md){

        }
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 25px;
            @include media-breakpoint-down(md){
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    &__nav1{
        background: #E9EAEE;
        border-radius: 20px;
        overflow: hidden;
        text-align: center;
        &__list1{
            display: flex;
            font-weight: bold;
            border-bottom: 1px solid #0067B2;
            @include media-breakpoint-down(lg){
                flex-wrap: wrap;
            }
            &__item{
                width: 100%;
                border-right: 1px solid #C3C3C3;
                @include media-breakpoint-down(lg){
                    width: 20%;
                    &:nth-child(1){
                        border-bottom: 1px solid #C3C3C3;
                    }
                    &:nth-child(2){
                        border-bottom: 1px solid #C3C3C3;
                    }
                    &:nth-child(3){
                        border-bottom: 1px solid #C3C3C3;
                    }
                    &:nth-child(4){
                        border-bottom: 1px solid #C3C3C3;
                    }
                    &:nth-child(5){
                        border-bottom: 1px solid #C3C3C3;
                        border-right: none;
                    }
                }
                &:last-child{
                    border-right: none;
                }
                a{
                    display: block;
                    padding: 10px;
                }
                &--active{
                    span{
                        display: block;
                        padding: 10px;
                        color: #fff;
                        background: #0067B2;
                    }
                }
            }
        }
        &__list2{
            display: flex;
            font-size: 18px;
            padding: 20px 10px;
            @include media-breakpoint-down(md){
                font-size: 15px;
            }
            &__item{
                a{
                    text-decoration: underline;
                    padding: 0 17px;
                }
                &--active{
                    span{
                        font-weight: bold;
                        padding: 0 17px;
                    }
                }
            }
        }
    }
    &__nav2{
        font-weight: bold;
        font-size: 18px;
        @include media-breakpoint-down(md){
            font-size: 15px;
        }
        &__list{
            display: flex;
            flex-wrap: wrap;
            border-left: 1px dashed #707070;
            &__item{
                width: 25%;
                border-right: 1px dashed #707070;
                position: relative;
                padding: 15px 20px 15px 40px;
                border-right: 1px dashed #707070;
                border-bottom: 1px dashed #707070;
                &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4){
                    border-top: 1px dashed #707070;
                }
                @include media-breakpoint-down(lg){
                    width: 50%;
                    padding: 10px 15px 10px 30px;
                    &:nth-child(3),&:nth-child(4){
                        border-top: none;
                    }
                }
                &:before{
                    position: absolute;
                    content: "";
                    top: 24px;
                    left: 20px;
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid #B2B2B2;
                    border-bottom: 2px solid #B2B2B2;
                    transform: rotate(-45deg);
                    @include media-breakpoint-down(lg){
                        width: 8px;
                        height: 8px;
                        top: 17px;
                        left: 15px;
                    }
                }
                a{
                    text-decoration: underline;
                }
            }
        }
    }
    &__nav3{
        font-size: 18px;
        @include media-breakpoint-down(md){
            font-size: 15px;
        }
        &__list{
            display: flex;
            flex-wrap: wrap;
            &__item{
                padding: 0 25px 0 20px;
                margin-bottom: 20px;
                position: relative;
                @include media-breakpoint-down(md){
                    padding: 0 20px 0 15px;
                }
                &:before{
                    position: absolute;
                    content: "";
                    top: 9px;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border-right: 2px solid #B2B2B2;
                    border-bottom: 2px solid #B2B2B2;
                    transform: rotate(-45deg);
                    @include media-breakpoint-down(lg){
                        top: 7px;
                        width: 8px;
                        height: 8px;
                    }
                }
                a{
                    text-decoration: underline;
                }
            }
        }
    }
}
