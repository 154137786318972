.age-check{
    min-height: calc(100vh - 52px);
    padding: 80px 15px;
    max-width: 562px;
    margin: 0 auto;
    text-align: center;
    @include media-breakpoint-down(md){
        min-height: calc(100vh - 92px);
    }
    &__ttl{
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 40px;
    }
    &__q{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &__txt{
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 40px;
        @include media-breakpoint-down(sm){
            font-size: 13px;
        }
    }
    &__box{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 50%;
            padding: 0 25px;
            @include media-breakpoint-down(sm){
                padding: 0 10px;
            }
        }
    }
    &__btn{
        display: block;
        font-size: 20px;
        font-weight: bold;
        border: 1px solid #AFAFAF;
        padding: 8px;
        border-radius: 30px;
        @include media-breakpoint-down(sm){
            font-size: 16px;
        }
        &--red{
            color: #fff;
            border-color: #D90B35;
            background: #D90B35;
            &:hover{
                color: #fff;
            }
        }
    }
}
