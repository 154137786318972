.article{
    &__section{
        padding: 25px 0 50px;
        @include media-breakpoint-down(sm){
            padding: 25px 0 35px;
        }
        &__header{
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 25px;
            @include media-breakpoint-down(md){
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
            &__discount{
                display: inline-block;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: bold;
                color: #FF0000;
            }
            &__tag{
                display: inline-flex;
                flex-wrap: wrap;
                font-size: 20px;
                font-weight: bold;
                color: #FD5A29;
            }
        }
        &__ttl{
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
        }
        &__container{
            display: flex;
            margin: 0 -15px;
        }
        &__main{
            width: calc(100% - 408px);
            padding: 0 15px;
            @include media-breakpoint-down(xl){
                width: 100%;
            }
        }
        &__side{
            position: relative;
            width: 408px;
            padding: 0 15px;
            @include media-breakpoint-down(xl){
                display: none;
            }
        }
        &__info{
            display: flex;
            margin-bottom: 30px;
            @include media-breakpoint-down(sm){
                margin-bottom: 10px;
            }
            &__left{
                width: 156px;
                @include media-breakpoint-down(sm){
                    width: 143px;
                }
            }
            &__right{
                width: calc(100% - 156px);
                padding-left: 23px;
                @include media-breakpoint-down(sm){
                    width: calc(100% - 143px);
                    padding-left: 17px;
                }
            }
        }
        &__img{
            margin-bottom: 3px;
            img{
                cursor: pointer;
            }
        }
        &__scaleUp{
            margin-bottom: 15px;
            font-size: 12px;
            text-align: center;
            width: 100%;
            color: #0067B2;
        }
        &__btn{
            &-wrapper{
                max-width: 156px;
            }
            display: block;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            padding: 3px;
            background: linear-gradient(to bottom, #fff, #ddd);
            border: 1px solid #C3C3C3;
            border-radius: 3px;
            width: 100%;
            margin-bottom: 5px;
            span{
                display: inline-block;
                @include triangle(5px, 9px, #DB002F, left);
                margin-right: -5px;
            }
        }
        &__download{
            display: block;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            padding: 3px;
            background: linear-gradient(to bottom, #fff, #ddd);
            border: 1px solid #C3C3C3;
            border-radius: 3px;
            width: 100%;
            margin-bottom: 5px;
            img{
                vertical-align: middle;
                margin-right: 5px;
                margin-bottom: 3px;
            }
        }
        &__star{
            margin-bottom: 10px;
            img{
                width: 90px;
            }
        }
        &__status{
            font-size: 12px;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px 15px;
            @include media-breakpoint-down(sm){
                margin-bottom: 10px;
            }
            li{
                background: #E9EAEE;
                font-size: 12px;
                font-weight: bold;
                padding: 4px 10px;
                border-radius: 13px;
                margin: 0 5px 5px;
                img{
                    margin-right: 5px;
                }
            }
        }
        &__tag{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -7px;
            li{
                padding: 0 7px;
                a{
                    color: #0067B2;
                }
            }
        }
        &__table{
            @include media-breakpoint-down(sm){
                width: 100%;
                margin-bottom: 25px;
                border-bottom: 1px solid #C3C3C3;
            }
            tr{
                th{
                    font-weight: normal;
                    padding: 0;
                    text-align: right;
                    white-space: nowrap;
                    vertical-align: top;
                    @include media-breakpoint-down(sm){
                        display: block;
                        padding: 5px 10px;
                        border-top: 1px solid #C3C3C3;
                        border-bottom: 1px solid #C3C3C3;
                        background: #E9EAEE;
                        text-align: left;
                        font-weight: bold;
                    }
                    &:after{
                        content: "\00a0\00a0：\00a0\00a0";
                        @include media-breakpoint-down(sm){
                            content: none;
                        }
                    }
                }
                td{
                    padding: 0;
                    vertical-align: top;
                    @include media-breakpoint-down(sm){
                        display: block;
                        padding: 5px 10px;
                    }
                }
            }
            &__star{
                display: inline-block;
                width: 90px;
                padding: 2px 0;
                line-height: 1;
                img{
                    vertical-align: top;
                }
            }
        }
        &__txt{
            margin-bottom: 30px;
        }
        &__content{
            margin-bottom: 30px;
            &__txt1{
                color: #FF0000;
                font-weight: bold;
                font-size: 15px;
                margin-bottom: 0;
                font-size: 15px;
            }
            &__txt2{
                font-weight: bold;
                margin-bottom: 0;
            }
        }
        &__gallery{
            margin-bottom: 34px;
            &__ttl{
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 5px;
                margin-bottom: 13px;
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -3px;
                @include media-breakpoint-down(sm){
                    flex-wrap: nowrap;
                    overflow: auto;
                    margin: 0 -15px;
                    padding: 0 12px;
                    width: calc(100% + 30px);
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    &::-webkit-scrollbar{
                        display:none;
                    }
                }
                li{
                    padding: 0 3px 6px;
                    cursor: pointer;
                    img{
                        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                        transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                        transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                        transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
                        z-index: 1;
                        height: 79px;
                        @include media-breakpoint-down(sm){
                            max-width: none;
                        }
                        &:hover{
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
        &__audio{
            margin-bottom: 34px;
            &__ttl{
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 5px;
                margin-bottom: 13px;
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
        }
        &__sttl{
            font-size: 15px;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        &__description{
            width: 100%;
            tr{
                border-bottom: 1px solid #C3C3C3;
                &:first-child{
                    border-top: 1px solid #C3C3C3;
                }
                th{
                    width: 12em;
                    background: #E9EAEE;
                    padding: 5px 1em;
                    @include media-breakpoint-down(sm){
                        width: auto;
                        display: block;
                        padding: 5px 10px;
                        border-bottom: 1px solid #C3C3C3;
                    }
                }
                td{
                    padding: 6px 15px;
                    @include media-breakpoint-down(sm){
                        display: block;
                        padding: 5px 10px;
                    }
                }
            }
        }
        &__extra{
            margin-bottom: 20px;
            &__ttl{
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 5px;
                margin-bottom: 13px;
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                margin: 0 -10px;
                li{
                    padding: 0 10px;
                    width: 33.33333%;
                    margin-bottom: 20px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }
            &__privilege{
                background: #E9EAEE;
                padding: 15px;
                text-align: center;
                &__inner{
                    max-width: 162px;
                    margin: 0 auto;
                    text-align: left;
                    cursor: pointer;
                    img{
                        margin-bottom: 10px;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__tokuten{
            margin-bottom: 20px;
            &__ttl{
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 5px;
                margin-bottom: 13px;
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                margin: 0 -10px;
                li{
                    padding: 0 10px;
                    width: 395px;
                    margin-bottom: 20px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }
            &__item{
                border: 1px dashed #000;
                padding: 15px;
                display: flex;
                &__inner{
                    text-align: left;
                    width: 50%;
                }
                &__ttl{
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                &__txt{
                    font-size: 12px;
                    margin: 0;
                }
                &__img{
                    padding-left: 15px;
                    width: 50%;
                    img{
                        width: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &__recommend{
        margin-bottom: 100px;
        @include media-breakpoint-down(sm){
            margin-bottom: 0;
        }
        &__item{
            border-bottom: 1px solid #C3C3C3;
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
        &__ttl{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        &__ttl2{
            font-size: 15px;
            font-weight: bold;
            padding: 10px 15px;
            margin-bottom: 20px;
            background: #0167B3;
            color: #fff;
        }
        &__ttl3{
            font-size: 15px;
            font-weight: bold;
            padding: 10px 15px;
            margin-bottom: 20px;
            background: #E9EAEE;
        }
    }
    &__review{
        margin-bottom: 80px;
        &__ttl{
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 10px;
            margin-bottom: 0;
            border-bottom: 2px solid #000;
        }
        &__header{
            border-bottom: 1px solid #000;
            &__top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 17px 0;
                border-bottom: 1px solid #C3C3C3;
                &__left{
                    display: flex;
                    align-items: center;
                }
            }
            &__bottom{
                padding: 20px 0 10px;
            }
            &__star{
                margin-right: 25px;
            }
            &__summary{
                margin-bottom: 0;
            }
            &__btn{
                color: #fff;
                font-weight: bold;
                background: #FD5A29;
                display: block;
                padding: 7px 15px;
                border-radius: 17px;
            }
        }
        &__sort{
            display: flex;
            align-items: center;
            margin-right: 20px;
            &__label{
                margin-right: 8px;
            }
            &__select{
                appearance: none;
                padding: 4px 20px 4px 5px;
                border-radius: 3px;
                border: none;
                width: 126px;
                border: 1px solid #A5A5A5;
                box-shadow: 0 1px 2px rgba(#000, .2);
                background: #fff;
                &-wrapper{
                    position: relative;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 6px;
                        height: 6px;
                        border-bottom: 1px solid #000;
                        border-right: 1px solid #000;
                        transform: rotate(45deg);
                        pointer-events: none;
                    }
                }
            }
        }
        &__list{
            margin-bottom: 20px;
        }
        &__item{
            border-bottom: 1px solid #000;
            padding-top: 25px;
            &__header{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 15px;
            }
            &__star{
                max-width: 84px;
                margin-right: 15px;
                line-height: 1;
            }
            &__ttl{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: bold;
            }
            &__main{
                margin-bottom: 20px;
            }
            &__footer{
                background: #F2F2F2;
                padding: 10px 15px;
            }
            &__reviewer{
                margin-bottom: 2px;
                a{
                    font-size: 14px;
                    font-weight: bold;
                    color: #0067B2;
                }
            }
            &__questionnaire{
                margin-bottom: 0;
                button{
                    background: #fff;
                    border: 1px solid #A5A5A5;
                    border-radius: 3px;
                    padding: 0 5px;
                    margin: 0 2px;
                    font-weight: bold;
                }
            }
        }
        &__pagination{
            text-align: right;
            .pagination{
                margin-bottom: 0;
            }
        }
    }
}
