.mypage{
    &__ttl{
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__summary{
        background: #E9EAEE;
        padding: 30px 30px 5px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md){
            padding: 20px 15px 5px;
        }
        &__header{
            border-bottom: 1px solid #000;
            padding-bottom: 5px;
            margin-bottom: 10px;
            @include clearfix();
        }
        &__ttl{
            float: left;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 0;
        }
        &__link{
            float: right;
            color: #0067B2;
            &:hover{
                color: #0067B2;
                text-decoration: underline;
            }
        }
        &__main{
            margin: 0 -7px 18px;
            display: flex;
            flex-wrap: wrap;
            @include media-breakpoint-down(md){
                margin: 0 -5px 5px;
            }
        }
        &__point{
            border: 1px solid #C3C3C3;
            background: #fff;
            font-size: 20px;
            font-weight: bold;
            padding: 15px;
            margin: 0 7px 14px;
            @include media-breakpoint-down(md){
                margin: 0 5px 5px;
            }
        }
        &__review{
            border: 1px solid #C3C3C3;
            background: #fff;
            margin: 0 7px 14px;
            @include media-breakpoint-down(md){
                margin: 0 5px 10px;
            }
            dt{
                padding: 2px 10px;
                margin-bottom: 0;
                text-align: center;
                color: #fff;
                background: #919191;
            }
            dd{
                padding: 5px 15px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }
    &__news{
        &__item{
            display: flex;
            margin-bottom: 20px;
            &__date{
                color: #838383;
                min-width: 6.5em;
                padding-right: 1em;
                margin-right: 1.2em;
                border-right: 1px solid #000;
                margin-bottom: 0;
            }
            &__ttl{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    &__search{
        background: #E9EAEE;
        padding: 10px 15px 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__right{
            display: flex;
        }
        &__box{
            margin: 5px 0;
            &__inner{
                display: inline-block;
            }
            &__ttl{
                margin-bottom: 0;
                font-size: 15px;
                font-weight: bold;
            }
            &__txt{
                display: inline-block;
                margin-right: 10px;
            }
            &__select{
                appearance: none;
                padding: 4px 20px 4px 5px;
                border-radius: 3px;
                border: none;
                width: 100%;
                box-shadow: 0 1px 2px rgba(#000, .2);
                background: #fff;
                &-wrapper{
                    position: relative;
                    display: inline-block;
                    margin: 5px 20px 5px 0;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 6px;
                        height: 6px;
                        border-bottom: 1px solid #000;
                        border-right: 1px solid #000;
                        transform: rotate(45deg);
                        pointer-events: none;
                    }
                }
            }
            &__btn{
                flex-grow: 1;
                padding: 4px 8px;
                margin: 0 2px;
                border-radius: 3px;
                box-shadow: 0 1px 2px rgba(#000, .2);
                text-align: center;
                background: #fff;
                &--active{
                    background: #0067B2;
                    color: #fff;
                }
                &-wrapper{
                    display: flex;
                    margin: 5px -2px;
                }
            }
            &__checkbox{
                &-wrapper{
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -7px;
                }
                margin: 5px 7px;
                display: flex;
                align-items: center;
                label{
                    padding-left: 5px;
                }
            }
        }
    }
    &__pagination{
        @include clearfix();
        &__left{
            float: left;
        }
        &__right{
            float: right;
        }
    }
    &__format{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: -2px;
        margin-bottom: 15px;
        &__txt{
            margin-bottom: 0;
            margin-right: 6px;
        }
        &__btn{
            padding: 3px;
            width: 32px;
            height: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 2px;
            border-radius: 3px;
            border: 1px solid #A5A5A5;
            text-align: center;
            background: #fff;
            &--active{
                background: #0067B2;
                color: #fff;
            }
        }
    }
    &__repeat {
        background: #E9EAEE;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 30px 0;
        padding: 10px 15px;
        &__txt {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
            margin-bottom: 0;
        }
        &__btn{
            background: #0067B2;
            display: inline-block;
            color: #fff;
            border-radius: 3px;
            font-weight: bold;
            margin: 0;
            padding: 3px 8px 3px 34px;
            position: relative;
            white-space: nowrap;
            &.inactive{
                pointer-events: none;
                background: #E9EAEE;
            }
            &:hover{
                color: #fff;
            }
            img{
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);
                width: 20px;
            }
        }
    }
    &__purchased{
        margin: 0 0 20px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &__table{
            width: 100%;
            thead{
                tr{
                    th{
                        background: #7E7E7E;
                        color: #fff;
                        padding: 12px 10px;
                        border-right: 1px solid #C3C3C3;
                        white-space: nowrap;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
            tbody{
                tr{
                    &:nth-child(even){
                        background: #E9EAEE;
                    }
                    &:last-child{
                        border-bottom: 1px solid #C3C3C3;
                    }
                    td{
                        padding: 15px 10px;
                        border-right: 1px solid #C3C3C3;
                        &:first-child{
                            white-space: nowrap;
                        }
                        &:last-child{
                            border-right: none;
                        }
                    }
                    &.bb{
                        border-bottom: 1px solid #C3C3C3;
                    }
                    &.mypage__purchased__desc{
                        background: none;
                        border-bottom: 1px dashed #C3C3C3;
                        font-size: 15px;
                        font-weight: bold;
                        &:last-of-type{
                            border-bottom: 1px solid #C3C3C3;
                        }
                        th{
                            padding: 15px 10px;
                        }
                        td{
                            border: none;
                            &:last-of-type{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
        &__product{
            display: flex;
            &__img{
                min-width: 75px;
                width: 75px;
                margin-right: 10px;
            }
            &__name{
                min-width: 150px;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 5px;
            }
            &__category{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2px;
                font-size: 12px;
                li{
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    margin: 0 2px 4px;
                    padding: 0 2px;
                }
            }
        }
        &__btn{
            background: #0067B2;
            display: inline-block;
            color: #fff;
            border-radius: 3px;
            font-weight: bold;
            margin: 0 0 4px;
            padding: 3px 8px 3px 20px;
            position: relative;
            white-space: nowrap;
            &.inactive{
                pointer-events: none;
                background: #E9EAEE;
            }
            &:hover{
                color: #fff;
            }
            img{
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%)
            }
            &--audio{
                img{
                    left: 6px;
                }
            }
        }
        &__dl{
            background: #0067B2;
            display: inline-block;
            color: #fff;
            border-radius: 3px;
            font-weight: bold;
            margin: 0 0 4px;
            padding: 3px 8px 3px 23px;
            position: relative;
            white-space: nowrap;
            &:hover{
                color: #fff;
            }
            img{
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%)
            }
        }
        &__detail{
            background: #0F6503;
            display: inline-block;
            color: #fff;
            border-radius: 3px;
            font-weight: bold;
            margin: 0 0 4px;
            padding: 3px 8px 3px 28px;
            position: relative;
            white-space: nowrap;
            &:hover{
                color: #fff;
            }
            img{
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%)
            }
        }
        &__link{
            color: #0067B2;
            text-decoration: underline;
            &:hover{
                color: #0067B2;
            }
        }
        &__folder{
            display: inline-block;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            padding: 2px 8px 2px 30px;
            background: linear-gradient(to bottom, #fff, #ddd);
            border: 1px solid #C3C3C3;
            border-radius: 3px;
            position: relative;
            white-space: nowrap;
            margin-bottom: 0;
            img{
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                margin-top: -2px;
            }
        }
        &__back{
            border: 1px solid #C09C58;
            border-radius: 3px;
            font-size: 20px;
            font-weight: bold;
            display: block;
            max-width: 272px;
            margin: 40px auto 0;
            text-align: center;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            padding: 12px;
            @include media-breakpoint-down(md){
                font-size: 15px;
            }
        }
    }
    &__favorite{
        &__table{
            &-wrapper{
                margin-bottom: 20px;
                overflow: auto;
            }
            width: 100%;
            &__tr{
                &:first-child{
                    .mypage__favorite__table__td{
                        border-top: 1px solid #000;
                    }
                }
            }
            &__td{
                border-bottom: 1px solid #000;
                &:first-child{
                    width: 182px;
                    min-width: 182px;
                    padding: 30px 25px 30px 0;
                    border-right: 1px solid #C3C3C3;
                    vertical-align: top;
                }
                &:nth-child(2){
                    padding: 30px 25px;
                    border-right: 1px solid #C3C3C3;
                    vertical-align: top;
                    min-width: 600px;
                }
                &:last-child{
                    text-align: center;
                    padding: 30px 25px;
                }
            }
            &__img{
                max-width: 156px;
                margin-bottom: 10px;
            }
            &__category{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2px 6px;
                font-size: 12px;
                li{
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    margin: 0 2px 4px;
                    padding: 0 2px;
                }
            }
            &__watch{
                display: block;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 3px 10px 3px 27px;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                width: 100%;
                margin-bottom: 5px;
                position: relative;
                span{
                    display: inline-block;
                    @include triangle(5px, 9px, #DB002F, left);
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                }
            }
            &__dl{
                display: block;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 3px 10px 3px 27px;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                width: 100%;
                margin-bottom: 5px;
                position: relative;
                img{
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                }
            }
            &__header{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                &__tags{
                    margin: 0 7px 0 -3px;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    li{
                        background: #7E7E7E;
                        margin: 6px 3px;
                        padding: 0 3px;
                        border-radius: 3px;
                    }
                }
                &__ttl{
                    font-size: 15px;
                    font-weight: bold;
                    color: #0067B2;
                    &:hover{
                        color: #0067B2;
                    }
                }
            }
            &__status{
                font-size: 12px;
                display: inline-flex;
                flex-wrap: wrap;
                margin: 0 15px 5px -5px;
                li{
                    background: #E9EAEE;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 4px 10px;
                    border-radius: 13px;
                    margin: 0 5px 5px;
                    img{
                        margin-right: 5px;
                    }
                }
            }
            &__star{
                display: inline-block;
                margin-bottom: 10px;
                img{
                    vertical-align: top;
                    width: 110px;
                }
            }
            &__data{
                margin-bottom: 5px;
                tr{
                    th{
                        font-weight: normal;
                        text-align: right;
                        padding: 0;
                    }
                    td{
                        padding: 0;
                        span{
                            color: #DB002F;
                        }
                    }
                }
            }
            &__tags{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;
                li{
                    margin: 0 5px 5px;
                    a{
                        color: #0067B2;
                    }
                }
            }
            &__txt{
                margin-bottom: 15px;
            }
            &__cart{
                display: inline-block;
                font-size: 15px;
                font-weight: bold;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                padding: 8px 15px 8px 40px;
                position: relative;
                img{
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                }
            }
            &__trash{
                display: block;
                width: 22px;
                margin: 0 auto 15px;
                img{
                    width: 100%;
                }
            }
            &__folder{
                display: inline-block;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 2px 8px 2px 30px;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                position: relative;
                white-space: nowrap;
                margin-bottom: 0;
                img{
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                    margin-top: -1px;
                }
            }
        }
        &__list{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;
            &__item{
                width: calc(16.66667% + 1px);
                border: 1px solid #C3C3C3;
                padding: 10px 15px;
                position: relative;
                margin: -1px -1px 0 0;
                @media (max-width: 1464px){
                    width: 20%;
                }
                @media (max-width: 1264px){
                    width: 25%;
                }
                @media (max-width: 1064px){
                    width: 33.33333%;
                }
                @include media-breakpoint-down(sm){
                    width: 50%;
                }
                &__inner{
                    max-width: 156px;
                    margin: 0 auto;
                }
                &__date{
                    margin-bottom: 10px;
                }
                &__img{
                    text-align: center;
                    margin-bottom: 10px;
                    a{
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
                &__name{
                    a{
                        color: #004CDB;
                        &:hover{
                            color: #004CDB;
                            text-decoration: underline;
                        }
                    }
                    color: #004CDB;
                    margin-bottom: 5px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                &__price{
                    margin-bottom: 10px;
                    &__main{
                        display: inline-block;
                        color: #DB002F;
                        font-weight: bold;
                    }
                    &__original{
                        display: inline-block;
                        text-decoration: line-through;
                    }
                }
                &__category{
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -2px 6px;
                    font-size: 12px;
                    li{
                        border: 1px solid #C3C3C3;
                        border-radius: 3px;
                        margin: 0 2px 4px;
                        padding: 0 2px;
                    }
                }
                &__cart{
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold;
                    background: linear-gradient(to bottom, #fff, #ddd);
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    padding: 3px 5px;
                    margin-bottom: 15px;
                    span{
                        position: relative;
                        padding: 0 0 0 20px;
                        img{
                            width: 15px;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
                &__menu{
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 10px;
                    margin: 0 -5px;
                    padding-right: 20px;
                    li{
                        padding: 0 5px;
                        margin-bottom: 10px;
                        a{
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            color: #0067B2;
                            white-space: nowrap;
                            img{
                                margin-right: 5px;
                            }
                        }
                    }
                }
                &__trash{
                    right: 0;
                    bottom: 0;
                    width: 34px;
                    height: 34px;
                    background: #E9EAEE;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    &__review{
        &__txt{
            font-size: 13px;
            color: #DB002F;
            margin-bottom: 30px;
            a{
                color: #0067B2;
                text-decoration: underline;
            }
        }
        &__tab{
            &__nav{
                border-bottom: 2px solid #0067B2;
                margin-bottom: 30px;
                display: flex;
                &__item{
                    font-size: 15px;
                    font-weight: bold;
                    color: #fff;
                    background: #C3C3C3;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    width: 226px;
                    max-width: calc(50% - 2px);
                    text-align: center;
                    cursor: pointer;
                    &:first-child{
                        margin-right: 4px;
                    }
                    &--active{
                        background: #0067B2;
                        cursor: auto;
                        padding: 10px;
                    }
                    a{
                        display: block;
                        padding: 10px;
                    }
                }
            }
            &__content{
                display: none;
                &--active{
                    display: block;
                }
            }
        }
        &__list{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            &__item{
                width: 16.66667%;
                margin-bottom: 50px;
                @media (max-width: 1464px){
                    width: 20%;
                }
                @media (max-width: 1264px){
                    width: 25%;
                }
                @media (max-width: 1064px){
                    width: 33.33333%;
                }
                @include media-breakpoint-down(sm){
                    width: 50%;
                }
                &__inner{
                    padding: 0 15px;
                    max-width: 186px;
                    margin: 0 auto;
                }
                &__img{
                    margin-bottom: 10px;
                }
                &__name{
                    a{
                        color: #004CDB;
                        &:hover{
                            color: #004CDB;
                            text-decoration: underline;
                        }
                    }
                    color: #004CDB;
                    margin-bottom: 5px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                &__category{
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -2px;
                    font-size: 12px;
                    li{
                        border: 1px solid #C3C3C3;
                        border-radius: 3px;
                        margin: 0 2px 4px;
                        padding: 0 2px;
                    }
                }
                &__star{
                    margin-bottom: 13px;
                    img{
                        width: 110px;
                    }
                }
                &__write{
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold;
                    background: linear-gradient(to bottom, #fff, #ddd);
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    padding: 3px 5px;
                    span{
                        padding-left: 15px;
                        position: relative;
                        &:before{
                            content: "";
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 12px;
                            height: 12px;
                            background-image: url(../images/icon-pen_black.svg);
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }
                &__watch{
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold;
                    background: #0067B2;
                    border-radius: 3px;
                    padding: 3px 5px;
                    color: #fff;
                    &:hover{
                        color: #fff;
                    }
                    span{
                        padding-left: 15px;
                        position: relative;
                        &:before{
                            content: "";
                            position: absolute;
                            top: 3px;
                            left: 0;
                            width: 12px;
                            height: 12px;
                            background-image: url(../images/icon-star.svg);
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        &__product{
            display: flex;
            margin-bottom: 40px;
            @include media-breakpoint-down(sm){
                padding: 0;
                border: none;
            }
            &__left{
                width: 156px;
                @include media-breakpoint-down(sm){
                    width: 115px;
                }
            }
            &__right{
                width: calc(100% - 156px);
                padding-left: 23px;
                @include media-breakpoint-down(sm){
                    width: calc(100% - 115px);
                }
            }
            &__img{
                max-width: 156px;
                margin-bottom: 10px;
            }
            &__category{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2px;
                font-size: 12px;
                li{
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    margin: 0 2px 4px;
                    padding: 0 2px;
                }
            }
            &__watch{
                display: block;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 3px 10px 3px 27px;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                width: 100%;
                margin-bottom: 5px;
                position: relative;
                span{
                    display: inline-block;
                    @include triangle(5px, 9px, #DB002F, left);
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                }
            }
            &__dl{
                display: block;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 3px 10px 3px 27px;
                background: linear-gradient(to bottom, #fff, #ddd);
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                width: 100%;
                margin-bottom: 5px;
                position: relative;
                img{
                    position: absolute;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                }
            }
            &__header{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                flex-wrap: wrap;
                &__tags{
                    margin: 0 7px 0 -3px;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    li{
                        background: #7E7E7E;
                        margin: 6px 3px;
                        padding: 0 3px;
                        border-radius: 3px;
                    }
                }
                &__ttl{
                    font-size: 15px;
                    font-weight: bold;
                    color: #0067B2;
                    &:hover{
                        color: #0067B2;
                    }
                }
            }
            &__status{
                font-size: 12px;
                display: inline-flex;
                flex-wrap: wrap;
                margin: 0 15px 5px -5px;
                li{
                    background: #E9EAEE;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 4px 10px;
                    border-radius: 13px;
                    margin: 0 5px 5px;
                    img{
                        margin-right: 5px;
                    }
                }
            }
            &__star{
                display: inline-block;
                margin-bottom: 10px;
                img{
                    vertical-align: top;
                    width: 110px;
                }
            }
            &__data{
                margin-bottom: 5px;
                tr{
                    th{
                        font-weight: normal;
                        text-align: right;
                        padding: 0;
                    }
                    td{
                        padding: 0;
                        span{
                            color: #DB002F;
                        }
                    }
                }
            }
            &__tags{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;
                li{
                    margin: 0 5px 5px;
                    a{
                        color: #0067B2;
                    }
                }
            }
            &__txt{
                margin-bottom: 15px;
            }
        }
        &__write{
            &__required{
                color: #f00;
                font-weight: bold;
                margin-bottom: 5px;
            }
            &__table{
                width: 100%;
                @include media-breakpoint-down(sm){
                    border-bottom: 1px solid #C3C3C3;
                }
                tr{
                    &:nth-child(even){
                        background: #e9eaee;
                        @include media-breakpoint-down(sm){
                            background: none;
                        }
                    }
                    th{
                        border: 1px solid #C3C3C3;
                        padding: 20px;
                        font-size: 18px;
                        vertical-align: top;
                        width: 200px;
                        @include media-breakpoint-down(sm){
                            width: 100%;
                            display: block;
                            border-bottom: none;
                            padding: 15px 15px 0;
                        }
                        span{
                            color: #f00;
                        }
                    }
                    td{
                        border: 1px solid #C3C3C3;
                        padding: 20px;
                        @include media-breakpoint-down(sm){
                            width: 100%;
                            display: block;
                            border-top: none;
                            border-bottom: none;
                            padding: 15px;
                        }
                    }
                }
            }
            &__error{
                font-size: 18px;
                color: #FF0000;
                margin-bottom: 0;
                position: relative;
                padding-left: 30px;
                padding-bottom: 10px;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                    padding-left: 24px;
                }
                img{
                    position: absolute;
                    top: 2px;
                    left: 0;
                    @include media-breakpoint-down(md){
                        width: 18px;
                    }
                }
            }
            &__input{
                display: block;
                border: 1px solid #919191;
                border-radius: 5px;
                width: 100%;
                max-width: 400px;
                font-size: 16px;
                padding: 10px;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
            &__checkbox{
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
                input{
                    font-size: 16px;
                }
            }
            &__textarea{
                display: block;
                border: 1px solid #919191;
                border-radius: 5px;
                width: 100%;
                max-width: 800px;
                font-size: 16px;
                padding: 10px;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
            &__txt{
                margin-top: 10px;
                margin-bottom: 0;
            }
            &__star{
                display: inline-flex;
                &__btn{
                    margin-right: 8px;
                    background-image: url(../images/star.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    img{
                        opacity: 0;
                    }
                    &--active{
                        background-image: none;
                        img{
                            opacity: 1;
                        }
                    }
                }
            }
            &__radio{
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                margin-right: 10px;
                input{
                    font-size: 16px;
                }
            }
            &__consent{
                font-size: 13px;
                color: #DB002F;
                margin: 40px 0;
                text-align: center;
                a{
                    color: #0067B2;
                    text-decoration: underline;
                }
            }
            &__submit{
                border: 1px solid #C09C58;
                border-radius: 3px;
                font-size: 20px;
                font-weight: bold;
                display: block;
                width: 272px;
                max-width: 100%;
                margin: 0 auto;
                text-align: center;
                background: linear-gradient(to bottom, #FFE596, #FFD250);
                padding: 12px;
                @include media-breakpoint-down(md){
                    font-size: 15px;
                }
            }
        }
        &__main{
            &__ttl{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            &__content{
                padding-top: 10px;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                &__header{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                }
                &__star{
                    max-width: 84px;
                    margin-right: 15px;
                    line-height: 1;
                }
                &__ttl{
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 0;
                }
                &__txt{
                    margin-bottom: 20px;
                }
                &__footer{
                    background: #f2f2f2;
                    padding: 10px 15px;
                }
                &__reviewer{
                    font-size: 14px;
                    color: #0067B2;
                    font-weight: bold;
                    margin-bottom: 2px;
                    small{
                        font-size: 13px;
                        color: #212529;
                        font-weight: normal;
                    }
                }
                &__vote{
                    margin-bottom: 0;
                }
            }
            &__btn-box{
                display: flex;
                flex-wrap: wrap;
                max-width: 600px;
                margin: 40px auto 0;
                li{
                    width: 50%;
                    padding: 0 15px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        padding: 0;
                    }
                    &:first-child{
                        @include media-breakpoint-down(sm){
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            &__btn{
                border: 1px solid #C09C58;
                border-radius: 3px;
                font-size: 20px;
                font-weight: bold;
                display: block;
                width: 100%;
                text-align: center;
                background: linear-gradient(to bottom, #FFE596, #FFD250);
                padding: 12px;
                @include media-breakpoint-down(md){
                    font-size: 15px;
                }
                &--gray{
                    border: 1px solid #C3C3C3;
                    background: linear-gradient(to bottom, #fff, #ddd);
                }
            }
        }
    }
    &__point{
        &__ttl{
            font-size: 18px;
            font-weight: bold;
            margin-top: 40px;
            margin-bottom: 20px;
        }
        &__table1{
            margin-top: 20px;
            border-top: 1px dashed #C3C3C3;
            width: 100%;
            font-size: 15px;
            tr{
                border-bottom: 1px dashed #C3C3C3;
                th{
                    padding: 15px;
                    font-weight: normal;
                    width: 30%;
                    min-width: 140px;
                }
                td{
                    padding: 15px;
                    em{
                        font-style: normal;
                            font-size: 25px;
                            font-weight: bold;
                    }
                }
            }
        }
        &__table2{
            margin-top: 20px;
            border-top: 1px dashed #C3C3C3;
            width: 100%;
            font-size: 15px;
            tr{
                border-bottom: 1px dashed #C3C3C3;
                th{
                    padding: 15px;
                    font-weight: normal;
                    width: 30%;
                    min-width: 140px;
                }
                td{
                    padding: 15px;
                }
            }
        }
        &__table3{
            margin-top: 20px;
            border-top: 1px solid #C3C3C3;
            width: 100%;
            font-size: 15px;
            tr{
                border-bottom: 1px solid #C3C3C3;
                td{
                    &:first-child{
                        width: 180px;
                    }
                    padding: 15px;
                    em{
                        font-style: normal;
                            font-size: 25px;
                            font-weight: bold;
                    }
                    a{
                        display: inline-block;
                        color: #fff;
                        background: #0067B2;
                        font-weight: bold;
                        padding: 3px 10px;
                        border-radius: 20px;
                        font-size: 13px;
                    }
                }
            }
        }
        &__method{
            margin-bottom: 40px;
            border-top: 1px solid #C3C3C3;
            &:last-of-type{
                margin-bottom: 0;
            }
            li{
                padding: 20px 0 20px 60px;
                border-bottom: 1px solid #C3C3C3;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            &__radio{
                margin-right: 20px;
                padding: 5px 0;
                input{
                    display: none;
                    &:checked{
                        & + label{
                            &:after{
                                position: absolute;
                                content: "";
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                background: #DB002F;
                                top: 5px;
                                left: -55px;
                            }
                        }
                    }
                }
                label{
                    font-size: 20px;
                    position: relative;
                    cursor: pointer;
                    &:before{
                        position: absolute;
                        content: "";
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                        border: 1px solid #C3C3C3;
                        top: 0;
                        left: -60px;
                    }
                    small{
                        font-size: 15px;
                        display: block;
                        line-height: 1.5;
                        margin-top: 5px;
                    }
                }
            }
            &__img{
                padding: 5px 0;
            }
        }
        &__table4{
            margin-top: 20px;
            border-top: 1px solid #C3C3C3;
            width: 100%;
            font-size: 15px;
            tr{
                border-bottom: 1px solid #C3C3C3;
                td{
                    &:first-child{
                        width: 180px;
                    }
                    padding: 15px;
                    span{
                        display: inline-block;
                        margin-bottom: 0;
                        color: #f00;
                        border: 1px solid #f00;
                        padding: 3px 10px;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
            &__select{
                appearance: none;
                padding: 8px 20px 8px 10px;
                border-radius: 3px;
                border: none;
                width: 100%;
                box-shadow: 0 1px 2px rgba(#000, .2);
                background: #fff;
                font-size: 15px;
                &-wrapper{
                    position: relative;
                    display: block;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 14px;
                        right: 8px;
                        width: 6px;
                        height: 6px;
                        border-bottom: 1px solid #000;
                        border-right: 1px solid #000;
                        transform: rotate(45deg);
                        pointer-events: none;
                    }
                }
            }
        }
        &__btn{
            border: 1px solid #C09C58;
            border-radius: 3px;
            font-size: 20px;
            font-weight: bold;
            display: block;
            width: 272px;
            max-width: 100%;
            margin: 40px auto 0;
            text-align: center;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            padding: 12px;
            @include media-breakpoint-down(md){
                font-size: 15px;
            }
        }
        &__about{
            margin-top: 60px;
            dt{
                margin-bottom: 5px;
            }
            dd{
                margin-bottom: 10px;
                a{
                    color: #0067B2;
                    text-decoration: underline;
                }
            }
        }
        &__confirm{
            &__table{
                &-wrapper{
                    border: 1px solid #C3C3C3;
                    max-width: 680px;
                    margin: 40px auto 0;
                    padding: 20px 15px;
                    @include media-breakpoint-down(sm){
                        padding: 20px 15px 5px;
                    }
                }
                max-width: 100%;
                margin: 0 auto;
                font-size: 20px;
                @include media-breakpoint-down(sm){
                    display: block;
                    font-size: 16px;
                }
                tr{
                    th{
                        padding: 15px;
                        @include media-breakpoint-down(sm){
                            display: block;
                            padding: 0;
                        }
                    }
                    td{
                        padding: 15px;
                        @include media-breakpoint-down(sm){
                            display: block;
                            padding: 0;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            &__btn-box{
                display: flex;
                flex-wrap: wrap;
                max-width: 600px;
                margin: 40px auto 0;
                li{
                    width: 50%;
                    padding: 0 15px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        padding: 0;
                    }
                    &:first-child{
                        @include media-breakpoint-down(sm){
                            margin-bottom: 20px;
                        }
                    }
                    p{
                        margin-top: 15px;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }
            }
            &__btn{
                border: 1px solid #C09C58;
                border-radius: 3px;
                font-size: 20px;
                font-weight: bold;
                display: block;
                width: 100%;
                text-align: center;
                background: linear-gradient(to bottom, #FFE596, #FFD250);
                padding: 12px;
                @include media-breakpoint-down(md){
                    font-size: 15px;
                }
                &--gray{
                    border: 1px solid #C3C3C3;
                    background: linear-gradient(to bottom, #fff, #ddd);
                }
            }
        }
    }
    &__account{
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 10px;
            @include media-breakpoint-down(md){
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
            span{
                font-size: 0.8em;
                font-weight: normal;
                padding-left: 1em;
            }
        }
        &__block{
            padding: 0 10px;
            border-bottom: 1px solid #000;
            $this: &;
            &__header{
                display: flex;
                padding: 25px 0;
                @include media-breakpoint-down(lg){
                    display: block;
                }
                &__ttl{
                    font-size: 20px;
                    font-weight: bold;
                    width: 10em;
                    margin: 0;
                    @include media-breakpoint-down(lg){
                        width: auto;
                        margin-bottom: 5px;
                    }
                }
                &__txt{
                    width: calc(100% - 10em);
                    margin: 0;
                    @include media-breakpoint-down(lg){
                        width: auto;
                    }
                }
            }
            &__row{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                padding: 25px;
                border-top: 1px dashed #000;
                @include media-breakpoint-down(lg){
                    display: block;
                    padding: 25px 15px;
                }
                dt{
                    font-weight: bold;
                    width: 16em;
                    @include media-breakpoint-down(lg){
                        width: auto;
                        margin-bottom: 10px;
                    }
                }
                dd{
                    margin-bottom: 0;
                    width: calc(100% - 16em);
                    @include media-breakpoint-down(lg){
                        width: auto;
                    }
                }
                &__id{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    span{
                        display: inline-block;
                        margin: 5px 0;
                    }
                }
                &__address{
                    display: flex;
                    align-items: center;
                    input{
                        margin-right: 20px;
                    }
                }
            }
        }
        &__input{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            max-width: 270px;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
            &--sm{
                max-width: 140px;
            }
            &--lg{
                max-width: 460px;
            }
        }
        &__btn1{
            font-size: 16px;
            font-weight: bold;
            padding: 5px 15px;
            color: #fff;
            background: #0067B2;
            &:hover{
                color: #fff;
            }
        }
        &__btn2{
            font-size: 16px;
            color: #fff;
            background: #989898;
            padding: 5px 10px;
            border-radius: 5px;
        }
        &__btn3{
            display: block;
            width: 100%;
            max-width: 376px;
            border: 1px solid #C09C58;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            border-radius: 3px;
            text-align: center;
            padding: 14px 10px;
            font-size: 20px;
            font-weight: bold;
            margin: 50px auto 0;
        }
        &__btn4{
            display: block;
            width: 100%;
            max-width: 200px;
            border: 1px solid #C3C3C3;
            background: linear-gradient(to bottom, #fff, #ddd);
            border-radius: 15px;
            padding: 5px 10px;
            margin: 50px auto 0;
            font-size: 15px;
            font-weight: bold;
        }
        &__error{
            font-size: 16px;
            color: #FF0000;
            padding: 10px 0;
            margin-bottom: 0;
            position: relative;
            padding-left: 30px;
            @include media-breakpoint-down(md){
                font-size: 14px;
                padding-left: 24px;
            }
            img{
                position: absolute;
                top: 12px;
                left: 0;
                @include media-breakpoint-down(md){
                    width: 18px;
                }
            }
        }
    }
    &__order{
        margin: 0 0 20px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &__table{
            width: 100%;
            thead{
                tr{
                    th{
                        background: #7E7E7E;
                        color: #fff;
                        padding: 12px 10px;
                        border-right: 1px solid #C3C3C3;
                        white-space: nowrap;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
            tbody{
                tr{
                    &:nth-child(even){
                        background: #E9EAEE;
                    }
                    &:last-child{
                        border-bottom: 1px solid #C3C3C3;
                    }
                    td{
                        padding: 15px 10px;
                        border-right: 1px solid #C3C3C3;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
        }
        &__detail{
            border-radius: 15px;
            font-size: 13px;
            font-weight: bold;
            display: inline-block;
            max-width: 100%;
            text-align: center;
            background: #0067B2;
            color: #fff;
            padding: 3px 12px;
            white-space: nowrap;
            &:hover{
                color: #fff;
            }
        }
        &__specification{
            margin: 0 0 20px;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            &__table{
                width: 100%;
                thead{
                    tr{
                        th{
                            background: #7E7E7E;
                            color: #fff;
                            padding: 12px 10px;
                            border-right: 1px solid #C3C3C3;
                            white-space: nowrap;
                            &:nth-child(2),&:nth-child(4),&:nth-child(5),&:nth-child(6),&:last-child{
                                border-right: none;
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        &:last-child{
                            border-bottom: 1px solid #C3C3C3;
                        }
                        th{
                            font-weight: bold;
                            padding: 15px 10px;
                            border-top: 1px dashed #C3C3C3;
                            border-bottom: 1px dashed #C3C3C3;
                            white-space: nowrap;
                        }
                        td{
                            padding: 15px 10px;
                            border-right: 1px solid #C3C3C3;
                            &:nth-child(2),&:nth-child(4),&:nth-child(5),&:nth-child(6),&:last-child{
                                border-right: none;
                            }
                        }
                        &.mypage__order__specification__table__main{
                            td{
                                border-right: none;
                            }
                        }
                    }
                }
            }
            &__btn{
                border: 1px solid #C09C58;
                border-radius: 3px;
                font-size: 20px;
                font-weight: bold;
                display: block;
                width: 272px;
                max-width: 100%;
                margin: 40px auto 0;
                text-align: center;
                background: linear-gradient(to bottom, #FFE596, #FFD250);
                padding: 12px;
                @include media-breakpoint-down(md){
                    font-size: 15px;
                }
            }
        }
    }
}
