@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: inherit;
    }
}
body{
    font-size: 13px;
    font-family: 'Noto Sans JP', sans-serif;
}
main{
    min-height: 100vh;
}
img{
    max-width: 100%;
    height: auto;
}
a{
    color: inherit;
    text-decoration: none;
    &:hover{
        color: inherit;
    }
}
ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
input, textarea, select{
    &:focus{
        outline: none;
    }
}
button{
    border: none;
    background: none;
    padding: 0;
    color: inherit;
}
