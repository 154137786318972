.buy-box{
    &__inner{
        background: #E9EAEE;
        padding: 12px;
        @include media-breakpoint-down(xl){
            margin-bottom: 40px;
        }
        @include media-breakpoint-down(sm){
            margin: 0 -15px 25px;
            padding: 15px;
        }
    }
    &__radio{
        background: #fff;
        border-bottom: 1px solid #C3C3C3;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding: 0 0 10px;
        cursor: pointer;
        dl{
            width: calc(100% - 10px);
            padding-left: 10px;
            margin-bottom: 0;
            text-align: left;
            dt{
                font-weight: normal;
                font-size: 16px;
                font-weight: bold;
                span{
                    color: #FD5A29;
                }
            }
            dd{
                font-size: 15px;
                margin-bottom: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-end;
                span{
                    font-size: 20px;
                    font-weight: bold;
                    color: #DB002F;
                }
                small{
                    font-size: 15px;
                    color: #606060;
                }
                s{
                    text-decoration: line-through;
                }
            }
        }
    }
    &__txt{
        margin-bottom: 15px;
    }
    &__buy{
        width: 100%;
        border: 1px solid #C09C58;
        background: linear-gradient(to bottom, #FFE596, #FFD250);
        border-radius: 3px;
        text-align: center;
        padding: 14px 10px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
        &--purchased{
            display: block;
        }
    }
    &__out{
        color: #fff;
        width: 100%;
        border: 1px solid #7B7B7B;
        background: linear-gradient(to bottom, #E2E2E2, #B4B4B4);
        border-radius: 3px;
        text-align: center;
        padding: 14px 10px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    &__cart{
        width: 100%;
        border: 1px solid #C3C3C3;
        background: linear-gradient(to bottom, #fff, #ddd);
        border-radius: 3px;
        padding: 8px 10px;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: bold;
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
    &__point{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 23px;
        font-size: 15px;
        margin-bottom: 10px;
        dt{
            font-size: 16px;
            font-weight: bold;
        }
        dd{
            font-size: 15px;
            margin-bottom: 0;
            span{
                font-size: 20px;
                font-weight: bold;
                color: #51AF61;
            }
        }
        &-text{
            text-align: left;
            background: #FFE5EF;
            padding: 8px 10px;
            margin-bottom: 10px;
            span{
                font-weight: bold;
                color: #FF5093;
            }
            a{
                color: #FF5093;
                text-decoration: underline;
            }
        }
    }
    &__stock{
        text-align: center;
        margin-bottom: 10px;
        background: #EDEDED;
        span{
            color: #DB002F;
            font-weight: bold;
        }
    }
    &__date {
        margin-bottom: 10px;
        dl {
            margin: 0;
            padding: 10px 0;
            dt {
                min-width: 7em;
                font-size: 16px;
            }
            dd {
                margin: 0;
                color: #FD5A29;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
    &__favorite{
        display: inline-block;
        border: none;
        font-size: 12px;
        color: #fff;
        text-align: center;
        background: #0067B2;
        font-weight: bold;
        padding: 3px 20px;
        border-radius: 12px;
        margin: 0 0 10px;
        img{
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    &__main{
        background: #fff;
        border: 1px solid #C3C3C3;
        padding: 12px;
        margin-bottom: 10px;
        text-align: center;
        @include media-breakpoint-down(sm){
            margin-bottom: 0;
        }
    }
    &__requirements{
        @include media-breakpoint-down(sm){
            display: none;
        }
        &__ttl{
            margin-bottom: 0;
            text-align: center;
            background: #000;
            color: #fff;
            font-weight: bold;
            padding: 8px;
            font-size: 15px;
        }
        &__list{
            padding: 15px;
            background: #fff;
            display: flex;
            justify-content: center;
            li{
                max-width: 50%;
                padding: 0 5px;
                dl{
                    display: flex;
                    margin-bottom: 0;
                    dt{
                        padding: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-right: 1px solid #C3C3C3;
                        width: calc(100% - 100px);
                    }
                    dd{
                        margin-bottom: 0;
                        display: flex;
                        width: 100px;
                        padding: 3px 6px;
                        div{
                            padding: 0 2px;
                            width: 50%;
                            text-align: center;
                            img{
                                margin-bottom: 5px;
                            }
                            p{
                                font-size: 10px;
                                line-height: 1.1;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &--fixed{
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1000;
        width: 253px;
        .buy-box{
            &__inner{
                box-shadow: 0 0 6px rgba(#000, .4);
                padding: 6px;
            }
            &__main{
                margin-bottom: 0;
                padding: 12px 6px;
            }
            &__requirements{
                display: none;
            }
            &__radio{
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 15px;
                dl{
                    dt{
                        font-size: 15px;
                    }
                    dd{
                        font-size: 13px;
                        span{
                            font-size: 15px;
                        }
                    }
                }
            }
            &__buy{
                font-size: 18px;
                padding: 6px 10px;
            }
            &__out{
                font-size: 18px;
                padding: 6px 10px;
            }
            &__cart{
                padding: 3px 10px;
            }
            &__point{
                dt{
                    font-size: 15px;
                }
                dd{
                    span{
                        font-size: 15px;
                    }
                    font-size: 13px;
                }
                &-text{
                    display: none;
                }
            }
            &__stock{
                display: none;
            }
            &__date {
                display: none;
                dl{
                    dt{
                        font-size: 15px;
                    }
                    dd{
                        font-size: 15px;
                    }
                }
            }
        }
        .discount-bal{
            box-shadow: 0 0 6px rgba(#000, .4);
            &:after{
                width: 28px;
                height: 28px;
                margin-left: -14px;
                background-image: url(../images/discount-bal-triangle_shadow.svg);
                background-size: 28px 28px;
            }
        }
    }
}

.discount-bal{
    background: linear-gradient(to bottom, #C60909, #DD5A30);
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 10px 12px;
    margin-bottom: 30px;
    position: relative;
    @include media-breakpoint-down(sm){
        margin-bottom: 22px;
    }
    &:after{
        pointer-events: none;
        content: "";
        position: absolute;
        width: 14px;
        height: 22px;
        top: 100%;
        left: 50%;
        margin-left: -7px;
        background-image: url(../images/discount-bal-triangle.svg);
        background-size: 14px 22px;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__main{
        font-size: 30px;
        margin-bottom: 0;
        line-height: 1.2;
    }
    &__price{
        font-size: 18px;
        margin-bottom: 0;
        s{
            margin-right: 0.5em;
        }
        span{
            margin-right: 0.5em;
        }
        em{
            font-size: 26px;
            font-style: normal;
        }
    }
    &__limit{
        font-size: 18px;
        margin-bottom: 0;
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
//   transform: translate3d(0, 0, 0);
}

.fade-enter-from,
.fade-leave-to {
//   transform: translate3d(0, -100%, 0);
    opacity: 0;
}
