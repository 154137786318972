.btn1{
    display: block;
    max-width: 165px;
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
    background: #0067B2;
    font-weight: bold;
    padding: 7px;
    border-radius: 17px;
    text-align: center;
    &:hover{
        color: #fff;
    }
}
