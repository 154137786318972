.bottom-nav{
    display: none;
    @include media-breakpoint-down(md){
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &__list{
        display: flex;
        &__item{
            width: 20%;
            border-right: 1px solid #fff;
            &:last-child{
                border-right: none;
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                background: #656565;
                padding: 0 7px;
            }
        }
    }
    &__btn{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border: none;
        width: 100%;
        background: #000;
        padding: 0 7px;
    }
}
