.new{
    &__section{
        padding: 25px 0 80px;
        @include media-breakpoint-down(md){
            padding-bottom: 0;
        }
        &__header{
            @include media-breakpoint-down(md){
                margin: 0 -15px 15px;
                padding: 0 15px 10px;
            }
        }
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 20px;
            @include media-breakpoint-down(md){
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &__search-result{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    &__nav{
        margin-bottom: 30px;
        @include media-breakpoint-down(sm){
            display: none;
        }
        &__top{
            @include clearfix();
        }
        &__bottom{
            background: #E9EAEE;
            padding: 8px;
            @include clearfix();
            &__left{
                float: left;
            }
            &__right{
                float: right;
                display: flex;
                align-items: center;
            }
        }
        &__pagination{
            &-nav{
                float: left;
            }
            float: right;
        }
        &__sort{
            display: flex;
            align-items: center;
            margin-right: 20px;
            &__label{
                margin-right: 8px;
            }
            &__select{
                appearance: none;
                padding: 4px 20px 4px 5px;
                border-radius: 3px;
                border: none;
                width: 126px;
                box-shadow: 0 1px 2px rgba(#000, .2);
                background: #fff;
                &-wrapper{
                    position: relative;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 6px;
                        height: 6px;
                        border-bottom: 1px solid #000;
                        border-right: 1px solid #000;
                        transform: rotate(45deg);
                        pointer-events: none;
                    }
                }
            }
        }
        &__display-number{
            display: flex;
            align-items: center;
            margin-right: 20px;
            &__txt{
                margin-bottom: 0;
                margin-right: 6px;
            }
            &__btn{
                padding: 4px 8px;
                margin: 0 2px;
                border-radius: 3px;
                box-shadow: 0 1px 2px rgba(#000, .2);
                text-align: center;
                background: #fff;
                &--active{
                    background: #0067B2;
                    color: #fff;
                }
            }
        }
        &__display-format{
            display: flex;
            align-items: center;
            &__txt{
                margin-bottom: 0;
                margin-right: 6px;
            }
            &__btn{
                padding: 3px;
                width: 32px;
                height: 27px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 2px;
                border-radius: 3px;
                box-shadow: 0 1px 2px rgba(#000, .2);
                text-align: center;
                background: #fff;
                &--active{
                    background: #0067B2;
                    color: #fff;
                }
            }
        }
    }
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    &__item{
        width: calc(16.66667% + 1px);
        padding: 0;
        border: 1px solid #C3C3C3;
        margin: -1px -1px 0 0;
        @media (max-width: 1464px){
            width: 20%;
        }
        @media (max-width: 1264px){
            width: 25%;
        }
        @media (max-width: 1064px){
            width: 33.33333%;
        }
        @include media-breakpoint-down(sm){
            border-right: none;
            border-left: none;
            border-top: none;
            width: 100%;
            padding: 0 0 20px;
            margin-bottom: 20px;
            &:last-child{
                border-bottom: none;
            }
        }
    }
    &__bottom{
        @include clearfix();
    }
    &__table{
        &-container{
            margin-bottom: 35px;
        }
        width: 100%;
        thead{
            tr{
                th{
                    background: #7E7E7E;
                    color: #fff;
                    font-weight: bold;
                    padding: 12px 10px;
                    &:not(:last-child){
                        border-right: 1px solid #C3C3C3;
                    }
                }
            }
        }
        tbody{
            tr{
                &:nth-child(2n){
                    background: #E9EAEE;
                }
                td{
                    padding: 12px 10px;
                    &:not(:last-child){
                        border-right: 1px solid #C3C3C3;
                    }
                }
            }
        }
        &__ttl{
            @include clearfix();
            a{
                display: inline-block;
                float: left;
                color: #004CDB;
                span{
                    color: #FD5A29;
                }
            }
            ul{
                float: right;
                display: inline-flex;
                font-size: 12px;
                li{
                    margin: 0 2px;
                    color: #fff;
                    font-weight: bold;
                    background: #7E7E7E;
                    border-radius: 3px;
                    padding: 0 3px;
                }
            }
        }
        &__bal{
            position: fixed;
            top: -200px;
            left: -200px;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
            box-shadow: 0 0 3px rgba(#000, .5);
            border-radius: 10px;
            &--active{
                opacity: 1;
                visibility: visible;
            }
            &__inner{
                background: #E9EAEE;
                border-radius: 10px;
                padding: 15px;
                position: relative;
            }
            &__main{
                width: 100px;
            }
            &__triangle{
                position: absolute;
                bottom: -20px;
                left: 55px;
                z-index: -1;
            }
        }
        &__category{
            width: 145px;
            @include media-breakpoint-down(lg){
                width: auto;
            }
        }
        &__price{
            width: 145px;
            @include media-breakpoint-down(lg){
                width: auto;
            }
            span{
                font-weight: bold;
                color: #DB002F;
            }
        }
    }
}
