.terms{
    &__container{
        padding: 50px 0 0;
    }
    &__nav{
        font-size: 16px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md){
            font-size: 14px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            li{
                padding: 0 10px;
                width: 16.66667%;
                margin: 0 0 20px;
                @media (max-width: 1480px){
                    width: 33.33333%;
                }
                @include media-breakpoint-down(lg){
                    width: 50%;
                }
                a{
                    display: block;
                    font-weight: bold;
                    background: #E9EAEE;
                    text-align: center;
                    padding: 10px;
                }
            }
        }
        &--payment{
            ul{
                li{
                    width: auto;
                    @include media-breakpoint-down(md){
                        width: 50%;
                    }
                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                    a{
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
    &__section{
        margin-bottom: 40px;
        figure{
            margin: 30px 0;
        }
        p{
            font-size: 16px;
            margin-bottom: 20px;
        }
        ol{
            padding-left: 2em;
            font-size: 16px;
            margin-bottom: 20px;
        }
        ul{
            padding-left: 2em;
            font-size: 16px;
            margin-bottom: 20px;
            list-style-type: disc;
        }
    }
    &__content{
        margin-top: 15px;
        padding: 0 15px 15px;
        &--payment{
            margin-top: 30px;
        }
    }
    &__ttl{
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md){
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
        &--sm{
            font-size: 18px;
            @include media-breakpoint-down(md){
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }
        &02{
            font-size: 18px;
            font-weight: bold;
            margin: 0;
            padding: 15px;
            background: #E9EAEE;
        }
        &03{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
    &__qa{
        font-size: 18px;
        margin-bottom: 20px;
        @include media-breakpoint-down(md){
            font-size: 14px;
        }
        dt{
            background: #E9EAEE;
            padding: 15px 15px 15px 70px;
            font-weight: normal;
            position: relative;
            @include media-breakpoint-down(md){
                padding-left: 55px;
            }
            &:before{
                content: "";
                position: absolute;
                top: 12px;
                left: 15px;
                width: 36px;
                height: 36px;
                background-image: url(../images/icon-q.svg);
                background-size: cover;
                @include media-breakpoint-down(md){
                    width: 28px;
                    height: 28px;
                }
            }
        }
        dd{
            padding: 15px 15px 15px 70px;
            margin: 0;
            position: relative;
            @include media-breakpoint-down(md){
                padding-left: 55px;
            }
            &:before{
                content: "";
                position: absolute;
                top: 12px;
                left: 15px;
                width: 36px;
                height: 36px;
                background-image: url(../images/icon-a.svg);
                background-size: cover;
                @include media-breakpoint-down(md){
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
    &__back{
        border: 1px solid #C3C3C3;
        border-radius: 3px;
        font-size: 15px;
        font-weight: bold;
        display: block;
        width: 100%;
        text-align: center;
        background: linear-gradient(to bottom, #fff, #ddd);
        padding: 8px;
        max-width: 280px;
        margin: 40px auto 0;
    }
}

