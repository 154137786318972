@mixin triangle($vertical, $horizontal, $color, $direction) {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $vertical $horizontal $vertical $horizontal;
    border-color: $color;
    @if ($direction == top) {
        border-color: $color transparent transparent transparent;
    }
    @if ($direction == right) {
        border-color: transparent $color transparent transparent;
    }
    @if ($direction == bottom) {
        border-color: transparent transparent $color transparent;
    }
    @if ($direction == left) {
        border-color: transparent transparent transparent $color;
    }
}
