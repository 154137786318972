.login{
    &-main{
        padding: 40px 0 100px;
        @include media-breakpoint-down(md){
            padding: 40px 0;
        }
        &__ttl{
            font-size: 23px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 40px;
        }
        &__txt{
            margin-bottom: 40px;
            a{
                text-decoration: underline;
            }
        }
        &__container{
            max-width: 1030px;
            margin: 0 auto;
            padding: 0 15px;
        }
    }
    &-box{
        border: 1px solid #000;
        &__ttl{
            font-size: 22px;
            text-align: center;
            color: #fff;
            background: #000;
            font-weight: bold;
            padding: 15px;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                font-size: 18px;
            }
        }
        &__main{
            padding: 40px 15px;
        }
        &__bottom{
            border-top: 1px solid #000;
            padding: 40px 15px;
        }
        &__container{
            margin: 0 auto;
            max-width: 860px;
        }
        &__sttl{
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 40px;
            @include media-breakpoint-down(md){
                font-size: 16px;
            }
        }
        &__error{
            font-size: 18px;
            color: #FF0000;
            padding: 10px 0;
            margin-bottom: 0;
            position: relative;
            padding-left: 30px;
            @include media-breakpoint-down(md){
                font-size: 14px;
                padding-left: 24px;
            }
            img{
                position: absolute;
                top: 12px;
                left: 0;
                @include media-breakpoint-down(md){
                    width: 18px;
                }
            }
        }
        &__input{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__checkbox{
            display: inline-flex;
            align-items: center;
            input[type=checkbox]{
                margin-right: 5px;
            }
            label{

            }
        }
        &__select{
            width: 100%;
            font-size: 16px;
            appearance: none;
            border: 1px solid #919191;
            border-radius: 5px;
            padding: 10px;
            background: none;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__btn{
            display: block;
            width: 100%;
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background: #DB002F;
            padding: 10px;
            border-radius: 25px;
            @include media-breakpoint-down(md){
                font-size: 16px;
            }
            &:hover{
                color: #fff;
            }
        }
        &__back{
            font-size: 16px;
            color: #004CDB;
            text-decoration: underline;
            &:hover{
                color: #004CDB;
            }
        }
        &__flow{
            display: flex;
            margin: 0 -2px;
            font-size: 12px;
            li{
                padding: 0 2px;
                width: 33.33333%;
                position: relative;
                &:first-child{
                    &:before{
                        content: none;
                    }
                }
                &:last-child{
                    &:after{
                        content: none;
                    }
                }
                &:before{
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    top: 0;
                    left: 2px;
                    border-style: solid;
                    border-width: 11px 6px;
                    border-color: transparent transparent transparent #fff;
                }
                &:after{
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    top: 0;
                    right: -10px;
                    border-style: solid;
                    border-width: 11px 6px;
                    border-color: transparent transparent transparent #919191;
                    z-index: 1;
                }
                span{
                    padding: 2px;
                    background: #919191;
                    display: block;
                    text-align: center;
                    color: #fff;
                }
                &.active{
                    &:after{
                        border-color: transparent transparent transparent #DB002F;
                    }
                    span{
                        background: #DB002F;
                    }
                }
            }
        }
        &__login{
            @include media-breakpoint-down(md){
                margin-bottom: 40px;
            }
            &__input-wrapper{
                margin-bottom: 15px;
            }
            &__checkbox-wrapper{
                margin-top: 15px;
                margin-bottom: 20px;
                text-align: center;
            }
            &__password{
                text-align: center;
                &__link{
                    color: #004CDB;
                    text-decoration: underline;
                }
            }
        }
        &__service{
            background: #F2F2F2;
            padding: 15px 20px 10px;
            &__ttl{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 15px;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;
                li{
                    width: 50%;
                    margin-bottom: 10px;
                    padding: 0 5px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                }
            }
            &__btn{
                display: block;
                background: #fff;
                border: 1px solid #919191;
                border-radius: 5px;
                padding: 8px;
                text-align: center;
                img{
                    margin-right: 10px;
                }
            }
        }
        &__reset{
            &__input-wrapper{
                margin-bottom: 15px;
            }
            &__txt{
                margin-bottom: 5px;
            }
            &__btn-wrapper{
                max-width: 240px;
                margin: 30px auto 0;
            }
        }
        &__join{
            @include media-breakpoint-down(md){
                margin-bottom: 40px;
            }
            &__ttl{
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 30px;
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
            &__flow-wrapper{
                margin-bottom: 30px;
            }
            &__input-wrapper{
                margin-bottom: 15px;
            }
            &__list{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;
                li{
                    width: 50%;
                    margin-bottom: 10px;
                    padding: 0 5px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                }
            }
            &__btn{
                display: block;
                background: #fff;
                border: 1px solid #919191;
                border-radius: 5px;
                padding: 8px;
                text-align: center;
                img{
                    margin-right: 10px;
                }
            }
        }
        &__regist{
            &__flow-wrapper{
                max-width: 350px;
                margin: 0 auto 40px;
            }
            &__container{
                max-width: 600px;
                margin: 0 auto;
            }
            &__table{
                width: 100%;
                margin-bottom: 20px;
                tr{
                    &:first-child{
                        th{
                            vertical-align: top;
                        }
                    }
                    th{
                        padding: 0 2em 30px 0;
                        font-size: 18px;
                        width: 8em;
                        min-width: 8em;
                        @include media-breakpoint-down(md){
                            font-size: 16px;
                            display: block;
                            width: 100%;
                            padding: 0;
                            margin-bottom: 10px;
                        }
                    }
                    td{
                        padding: 0 0 30px 0;
                        word-break: break-all;
                        @include media-breakpoint-down(md){
                            display: block;
                            width: 100%;
                            padding: 0;
                            margin-bottom: 35px;
                        }
                    }
                }
            }
            &__e-mail{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
                @include media-breakpoint-down(md){
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            &__password{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
                position: relative;
                display: inline-block;
                padding-right: 40px;
                @include media-breakpoint-down(md){
                    font-size: 16px;
                    font-weight: normal;
                }
                &__btn{
                    position: absolute;
                    width: 27px;
                    height: 27px;
                    top: 0;
                    right: 0;
                    background-image: url(../images/icon-eye_hide.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 27px;
                    &--open{
                        background-image: url(../images/icon-eye.svg);
                    }
                }
            }
            &__birthday{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
                @include media-breakpoint-down(md){
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            &__note{
                margin-bottom: 0;
                font-size: 14px;
            }
            &__input-wrapper{
                position: relative;
                .login-box__input{
                    padding-right: 44px;
                }
            }
            &__toggler{
                position: absolute;
                width: 44px;
                height: 44px;
                top: 1px;
                right: 1px;
                border-radius: 4px;
                background-image: url(../images/icon-eye_hide.svg);
                background-repeat: no-repeat;
                background-position: center;
                &--open{
                    background-image: url(../images/icon-eye.svg);
                }
            }
            &__select-wrapper{
                display: flex;
                align-items: flex-end;
                span{
                    min-width: 3em;
                    text-align: center;
                }
            }
            &__consent{
                text-align: center;
                margin-bottom: 40px;
                a{
                    color: #0067B2;
                    text-decoration: underline;
                }
            }
            &__complete{
                text-align: center;
                margin-bottom: 100px;
            }
        }
    }
}
