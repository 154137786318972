// オプションB: Bootstrapを部分的に組み込む

// 1. 最初に関数を組み込む（カラー、SVG、計算などが操作できるように）
@import "bootstrap/scss/functions";

// 2. デフォルトの変数の再定義を組み込む

// 3. 残りの必須のBootstrapスタイルシートを組み込む
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 4. 必要に応じて、オプションのBootstrapCSSを組み込む
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";

// 5. 最後にオプションで、ユーティリティAPIを組み込んで、`_utililies.scss`のSassマップに基づいてクラスを生成
@import "bootstrap/scss/utilities/api";

// 6. ここにカスタムコードを追加
$font-family-sans-serif: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", sans-serif;

body{
    font-family: $font-family-sans-serif;
}
