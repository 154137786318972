.new-arrival{
    &__container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -22px;
        @include media-breakpoint-down(sm){
            margin: 0 -5px;
        }
    }
    &__item{
        width: 16.66667%;
        padding: 0 19px;
        margin-bottom: 40px;
        @media (max-width: 1464px){
            width: 20%;
        }
        @media (max-width: 1264px){
            width: 25%;
        }
        @media (max-width: 1064px){
            width: 33.33333%;
        }
        @include media-breakpoint-down(sm){
            padding: 0 5px;
            margin-bottom: 20px;
        }
        &--wide{
            width: 20%;
            @media (max-width: 1464px){
                width: 25%;
            }
            @media (max-width: 1264px){
                width: 33.33333%;
            }
            @media (max-width: 1064px){
                width: 50%;
            }
            @include media-breakpoint-down(sm){
                padding: 0 5px;
                margin-bottom: 20px;
            }
        }
    }
}
