.doujin{
    &-category{
        &-ranking{
            &__ttl{
                font-size: 16px;
                font-weight: bold;
                background: #E9EAEE;
                padding: 5px 10px;
                margin-bottom: 25px;
            }
            &__list{
                margin-bottom: 25px;
                &__item{
                    margin-bottom: 25px;
                    @include media-breakpoint-down(sm){
                        margin-bottom: 10px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
