.side{
    // position: relative;
    // z-index: 1;
    border-right: solid 1px #C3C3C3;
    width: 227px;
    padding-bottom: 70px;
    @include media-breakpoint-up(md){
        display: block !important;
    }
    @include media-breakpoint-down(md){
        position: fixed;
        z-index: 999;
        top: auto;
        left: 0;
        right: 0;
        width: auto;
        bottom: 0;
        height: 100%;
        background: #fff;
        overflow-y: auto;
        scrollbar-width: none;
        display: none;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 50px;
        border-right: none;
        &::-webkit-scrollbar{
            display:none;
        }
    }
    &__menu{
        font-size: 14px;
        @include media-breakpoint-down(md){
            display: none;
        }
        &__item{
            position: relative;
            &:hover{
                .side__menu__link{
                    background: #E9EAEE;
                    color: inherit;
                    &:after{
                        border-color: #DB002F;
                    }
                }
                .side__sub-menu{
                    display: block;
                }
            }
        }
        &__link{
            display: block;
            padding: 9px 25px 9px 15px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                border-style: solid;
                border-width: 2px 2px 0 0;
                border-color: #DB002F;
                top: 50%;
                right:  15px;
                transform: rotate(45deg)translateY(-50%);
            }
            &--active{
                background: #DB002F;
                color: #fff;
                &:after{
                    border-color: #fff;
                }
            }
        }
    }
    &__sub-menu{
        display: none;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 100%;
        width: 224px;
        background: #E9EAEE;
        font-size: 14px;
        &__list{
            &__item{

            }
            &__link{
                display: block;
                padding: 9px 15px;
                &:hover{
                    background: #DB002F;
                    color: #fff;
                }
            }
        }
        &__sub-list{
            &__item{

            }
            &__link{
                display: block;
                padding: 9px 15px 9px 30px;
                &:hover{
                    background: #DB002F;
                    color: #fff;
                }
            }
        }
    }
    &__nav{
        &__block{
            border-top: 1px solid #C3C3C3;
            &__ttl{
                font-size: 14px;
                font-weight: bold;
                background: #F2F2F2;
                padding: 6px 15px;
                margin-bottom: 0;
                display: block;
            }
            &__list{
                padding: 10px 15px;
                @include media-breakpoint-down(md){
                    padding: 0;
                }
                &__item{
                    position: relative;
                    padding: 5px 0 5px 15px;
                    @include media-breakpoint-down(md){
                        padding: 0;
                        border-bottom: 1px solid #D5D5D5;
                    }
                    &:last-child{
                        @include media-breakpoint-down(md){
                            border-bottom: none;
                        }
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-style: solid;
                        border-width: 2px 2px 0 0;
                        border-color: #B2B2B2;
                        top: 12px;
                        left: 0;
                        transform: rotate(45deg);
                        @include media-breakpoint-down(md){
                            left: auto;
                            right: 15px;
                            top: 16px;
                        }
                    }
                }
                &__link{
                    @include media-breakpoint-down(md){
                        display: block;
                        padding: 11px 25px 11px 15px;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                    &--bdb{
                        @include media-breakpoint-down(md){
                            border-bottom: 1px solid #C3C3C3;
                        }
                    }
                    .underline{
                        background: linear-gradient(to bottom, transparent 0%, transparent 60%, #FFAFFF 60%, #FFAFFF 100%);
                    }
                    .new{
                        color: #FF0000;
                        font-weight: bold;
                        font-size: 10px;
                        padding-left: 5px;
                    }
                }
            }
            &__bottom{
                text-align: right;
                padding: 0 15px 15px;
                @include media-breakpoint-down(md){
                    padding: 0;
                    text-align: left;
                }
            }
            &__link{
                display: inline-block;
                padding-left: 15px;
                position: relative;
                @include media-breakpoint-down(md){
                    display: block;
                    padding: 11px 25px 11px 15px;
                    border-top: 1px solid #C3C3C3;
                }
                &:before{
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    border-color: #B2B2B2;
                    top: 6px;
                    left: 0;
                    transform: rotate(45deg);
                    @include media-breakpoint-down(md){
                        left: auto;
                        right: 15px;
                        top: 16px;
                    }
                }
            }
        }
        &--mypage{
            .side__nav{
                &__block{
                    &:first-of-type{
                        border-top: none;
                    }
                    a.side__nav__block__ttl{
                        position: relative;
                        padding-right: 20px;
                        @include media-breakpoint-down(md){
                            padding: 11px 25px 11px 15px;
                        }
                        &:after{
                            position: absolute;
                            content: "";
                            width: 8px;
                            height: 8px;
                            border-top: 2px solid #DB0A2F;
                            border-right: 2px solid #DB0A2F;
                            top: 50%;
                            right: 12px;
                            transform: translateY(-50%)rotate(45deg);
                            @include media-breakpoint-down(md){
                                width: 7px;
                                height: 7px;
                                right: 15px;
                            }
                        }
                    }
                    &__ttl{
                        @include media-breakpoint-down(md){
                            padding: 11px 25px 11px 15px;
                        }
                    }
                    &__folder{
                        padding: 0 15px 20px;
                        @include media-breakpoint-down(md){
                            padding: 15px 15px 20px;
                        }
                        &__ttl{
                            font-weight: bold;
                            margin-bottom: 5px;
                        }
                        &__list{
                            border: 1px solid #C3C3C3;
                            padding: 5px 7px 5px 13px;
                            &__item{
                                padding: 4px 0;
                                display: flex;
                            }
                            &__link{
                                display: inline-block;
                                padding-left: 22px;
                                background-image: url(../images/icon-folder.svg);
                                background-repeat: no-repeat;
                                width: calc(100% - 40px);
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                            &__menu{
                                display: flex;
                                width: 40px;
                                li{
                                    width: 50%;
                                }
                                &__btn{
                                    display: block;
                                    text-align: center;
                                    width: 100%;
                                }
                            }
                            &__add{
                                display: inline-block;
                                padding-left: 22px;
                                background-image: url(../images/icon-folder_plus.svg);
                                background-repeat: no-repeat;
                                color: #0067B2;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
                &__link{
                    display: block;
                    padding: 6px 20px 6px 15px;
                    font-size: 14px;
                    font-weight: bold;
                    border-top: 1px solid #C3C3C3;
                    position: relative;
                    @include media-breakpoint-down(md){
                        padding: 11px 25px 11px 15px;
                    }
                    &:after{
                        position: absolute;
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid #DB0A2F;
                        border-right: 2px solid #DB0A2F;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%)rotate(45deg);
                        @include media-breakpoint-down(md){
                            width: 7px;
                            height: 7px;
                            right: 15px;
                        }
                    }
                    &--bdb{
                        @include media-breakpoint-down(md){
                            border-bottom: 1px solid #C3C3C3;
                        }
                    }
                }
            }
        }
    }
    &__banner{
        border-top: solid 1px #C3C3C3;
        padding: 20px 15px;
        @include media-breakpoint-down(md){
            display: none;
        }
        &__list{
            &__item{
                text-align: center;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
            }
            &__link{

            }
        }
    }
    &__news{
        border-top: solid 1px #C3C3C3;
        @include media-breakpoint-down(md){
            display: none;
        }
        &__ttl{
            font-size: 14px;
            font-weight: bold;
            background: #F2F2F2;
            padding: 6px 15px;
            margin-bottom: 0;
        }
        &__list{
            &__item{
                margin-bottom: 10px;
                &__link{
                    &:hover{
                        .side__news__list__item__txt{
                            text-decoration: underline;
                        }
                    }
                }
                &__date{
                    color: #838383;
                }
                &__txt{
                    margin-bottom: 0;
                }
            }
        }
        &__inner{
            padding: 10px 15px;
        }
        &__bottom{
            text-align: right;
            margin-bottom: 20px;
            &__link{
                color: #0067B2;
                &:hover{
                    color: #0067B2;
                    text-decoration: underline;
                }
            }
        }
    }
    &__twitter{
        padding: 10px 15px;
        @include media-breakpoint-down(md){
            display: none;
        }
    }
}
