.buy{
    &-main{
        padding: 40px 0 100px;
        @include media-breakpoint-down(md){
            padding: 40px 0;
        }
        &__ttl{
            font-size: 23px;
            font-weight: bold;
            margin-bottom: 40px;
        }
        &__container{
            max-width: 1030px;
            margin: 0 auto;
            padding: 0 15px;
        }
        &__row{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
        }
        &__left{
            width: calc(100% - 344px);
            padding: 0 15px;
            @include media-breakpoint-down(md){
                width: 100%;
                margin-bottom: 40px;
            }
        }
        &__right{
            width: 344px;
            padding: 0 15px;
            @include media-breakpoint-down(md){
                width: 100%;
            }
        }
    }
    &-list{
        &__item{
            padding-bottom: 18px;
            border-bottom: 1px solid #C3C3C3;
            margin-bottom: 18px;
            &:last-child{
                margin-bottom: 0;
            }
            &__inner{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            &__img{
                width: 130px;
                padding-right: 15px;
                a{
                    &:hover{
                        img{
                            opacity: 0.8;
                        }
                    }
                }
            }
            &__main{
                width: calc(100% - 130px - 14px);
                padding-right: 15px;
            }
            &__trash{
                width: 14px;
                align-self: center;
                background: none;
                border: none;
                padding: 0;
                line-height: 1;
            }
            &__menu{
                width: 272px;
                @include media-breakpoint-down(md){
                    width: calc(100% + 20px);
                    margin: 20px -10px 0;
                    display: flex;
                }
                li{
                    margin-bottom: 20px;
                    @include media-breakpoint-down(md){
                        width: 50%;
                        padding: 0 10px;
                        margin-bottom: 0;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            &__tags{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2px 5px;
                li{
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    background: #7E7E7E;
                    margin: 0 2px 4px;
                    padding: 0 3px;
                    border-radius: 3px;
                }
            }
            &__name{
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 10px;
                a{
                    color: #0067B2;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            &__status{
                font-size: 12px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px 5px;
                li{
                    background: #E9EAEE;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 4px 10px;
                    border-radius: 13px;
                    margin: 0 5px 5px;
                    img{
                        margin-right: 5px;
                    }
                }
            }
            &__data{
                margin-bottom: 10px;
                tr{
                    th{
                        font-weight: normal;
                        text-align: right;
                        padding: 0;
                    }
                    td{
                        padding: 0;
                        span{
                            color: #DB002F;
                        }
                    }
                }
            }
            &__categories{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2px 0;
                li{
                    margin: 0 2px 4px;
                    border: 1px solid #C3C3C3;
                    border-radius: 3px;
                    font-size: 12px;
                    a{
                        padding: 0 3px;
                    }
                }
            }
            &__note{
                color: #DB002F;
                margin: 5px 0 0;
            }
            &__select{
                appearance: none;
                padding: 4px 20px 4px 5px;
                border-radius: 3px;
                border: none;
                width: 100%;
                box-shadow: 0 1px 2px rgba(#000, .2);
                background: #fff;
                &-wrapper{
                    position: relative;
                    display: inline-block;
                    margin: 10px 0 0;
                    &:after{
                        content: "";
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 6px;
                        height: 6px;
                        border-bottom: 1px solid #000;
                        border-right: 1px solid #000;
                        transform: rotate(45deg);
                        pointer-events: none;
                    }
                }
            }
        }
        &--complete{
            .buy-list{
                &__item{
                    &__main{
                        width: calc(100% - 130px - 272px);
                        @include media-breakpoint-down(md){
                            width: calc(100% - 130px);
                        }
                    }
                }
            }
        }
    }
    &-step{
        text-align: center;
        margin-bottom: 20px;
    }
    &-payment{
        border: 1px solid #C3C3C3;
        padding: 20px;
        &__summary{
            width: 100%;
            margin-bottom: 20px;
            tr{
                th{
                    font-weight: normal;
                    padding: 0;
                }
                td{
                    padding: 0;
                    text-align: right;
                    em{
                        font-size: 20px;
                        font-style: normal;
                        font-weight: bold;
                        color: #DB002F;
                    }
                    span{
                        color: #DB002F;
                    }
                }
            }
        }
        &__btn{
            border: 1px solid #C09C58;
            border-radius: 3px;
            font-size: 20px;
            font-weight: bold;
            display: block;
            width: 100%;
            text-align: center;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            padding: 12px;
        }
        &__login{
            margin-top: 10px;
            border: 1px solid #C3C3C3;
            border-radius: 3px;
            font-size: 15px;
            font-weight: bold;
            display: block;
            width: 100%;
            text-align: center;
            background: linear-gradient(to bottom, #fff, #ddd);
            padding: 8px;
        }
        &__back{
            margin-top: 10px;
            border: 1px solid #C3C3C3;
            border-radius: 3px;
            font-size: 15px;
            font-weight: bold;
            display: block;
            width: 100%;
            text-align: center;
            background: linear-gradient(to bottom, #fff, #ddd);
            padding: 8px;
        }
        &__hr{
            margin: 15px 0;
            border-color: #C3C3C3;
        }
        &__txt{
            margin-top: 10px;
            margin-bottom: 0;
        }
        &__txt{
            margin-top: 10px;
            margin-bottom: 0;
        }
        &__link{
            margin-top: 10px;
            margin-bottom: 0;
            color: #2967B2;
            text-decoration: underline;
            display: inline-block;
            &:hover{
                color: #2967B2;
            }
        }
        &__back{
            margin-top: 15px;
            font-size: 15px;
            text-align: center;
            margin-bottom: 0;
            a{
                text-decoration: underline;
            }
        }
    }
    &-message{
        border: 1px solid #DB002F;
        padding: 13px;
        background: #FFF7F9;
        color: #DB002F;
        margin-top: 20px;
        &__ttl{
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;
        }
        &__txt{
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    &-setting{
        width: 100%;
        margin-bottom: 40px;
        tr{
            border-bottom: 1px solid #C3C3C3;
            &:first-child{
                th{
                    padding-top: 0;
                }
                td{
                    padding-top: 0;
                }
            }
            th{
                padding: 20px 0;
                width: 9em;
                font-size: 15px;
                font-weight: bold;
                @include media-breakpoint-down(md){
                    width: 7em;
                }
            }
            td{
                padding: 20px 0;
                font-size: 15px;
                &:last-of-type{
                    width: 90px;
                    padding-left: 20px;
                }
            }
        }
        &__btn{
            font-size: 13px;
            font-weight: bold;
            color: #fff;
            background: #0067B2;
            display: block;
            border-radius: 17px;
            text-align: center;
            padding: 3px;
            max-width: 70px;
            &:hover{
                color: #fff;
            }
        }
        &__block{
            border-bottom: 1px solid #000;
            font-size: 16px;
            $this: &;
            &:first-of-type{
                border-top: 1px solid #000;
            }
            &__header{
                display: flex;
                padding: 25px 0;
                @include media-breakpoint-down(lg){
                    display: block;
                }
                &__ttl{
                    font-size: 20px;
                    font-weight: bold;
                    width: 8em;
                    margin: 0;
                    @include media-breakpoint-down(lg){
                        width: auto;
                        margin-bottom: 5px;
                    }
                }
                &__txt{
                    width: calc(100% - 8em);
                    margin: 0;
                    @include media-breakpoint-down(lg){
                        width: auto;
                    }
                }
                &__desc{
                    width: calc(100% - 8em);
                    margin: 0;
                    display: flex;
                    flex-wrap: wrap;
                    @include media-breakpoint-down(lg){
                        width: auto;
                    }
                    dt{
                        font-weight: normal;
                    }
                    dd{
                        margin-bottom: 0;
                        margin-right: 1em;
                        color: #FF0000;
                    }
                }
            }
            &__row{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                padding: 25px;
                border-top: 1px dashed #000;
                @include media-breakpoint-down(lg){
                    display: block;
                    padding: 25px 15px;
                }
                dt{
                    font-weight: bold;
                    width: 11em;
                    @include media-breakpoint-down(lg){
                        width: auto;
                        margin-bottom: 10px;
                    }
                    span{
                        color: #FF0000;
                    }
                }
                dd{
                    margin-bottom: 0;
                    width: calc(100% - 11em);
                    @include media-breakpoint-down(lg){
                        width: auto;
                    }
                }
                &__id{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    span{
                        display: inline-block;
                        margin: 5px 0;
                    }
                }
                &__address{
                    display: flex;
                    align-items: center;
                    input{
                        margin-right: 20px;
                    }
                }
            }
        }
        &__input{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            max-width: 270px;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
            &--sm{
                max-width: 140px;
            }
            &--lg{
                max-width: 460px;
            }
        }
        &__select{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            max-width: 270px;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__btn1{
            font-size: 16px;
            font-weight: bold;
            padding: 5px 15px;
            color: #fff;
            background: #0067B2;
            &:hover{
                color: #fff;
            }
        }
        &__btn2{
            font-size: 16px;
            color: #fff;
            background: #989898;
            padding: 5px 10px;
            border-radius: 5px;
        }
        &__btn3{
            display: block;
            width: 100%;
            max-width: 376px;
            border: 1px solid #C09C58;
            background: linear-gradient(to bottom, #FFE596, #FFD250);
            border-radius: 3px;
            text-align: center;
            padding: 14px 10px;
            font-size: 20px;
            font-weight: bold;
            margin: 50px auto 0;
        }
        &__btn4{
            display: block;
            width: 100%;
            max-width: 200px;
            border: 1px solid #C3C3C3;
            background: linear-gradient(to bottom, #fff, #ddd);
            border-radius: 15px;
            padding: 5px 10px;
            margin: 50px auto 0;
            font-size: 15px;
            font-weight: bold;
        }
        &__error{
            font-size: 16px;
            color: #FF0000;
            padding: 10px 0;
            margin-bottom: 0;
            position: relative;
            padding-left: 30px;
            @include media-breakpoint-down(md){
                font-size: 14px;
                padding-left: 24px;
            }
            img{
                position: absolute;
                top: 12px;
                left: 0;
                @include media-breakpoint-down(md){
                    width: 18px;
                }
            }
        }
    }
    &-sub-header{
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
        }
        &__btn{
            color: #0067B2;
        }
    }
    &-complete{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 40px;
        &__left{
            margin-bottom: 0;
            width: calc(100% - 302px);
            @include media-breakpoint-down(md){
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
            }
        }
        &__right{
            width: 302px;
            padding-left: 30px;
            @include media-breakpoint-down(md){
                width: 100%;
                padding-left: 0;
            }
        }
    }
    &-btn{
        border: 1px solid #C09C58;
        border-radius: 3px;
        font-size: 20px;
        font-weight: bold;
        display: block;
        max-width: 272px;
        margin: 0 auto;
        text-align: center;
        background: linear-gradient(to bottom, #FFE596, #FFD250);
        padding: 12px;
        @include media-breakpoint-down(md){
            font-size: 15px;
        }
    }
    &-bottom{
        margin-top: 40px;
        &__txt{
            text-align: center;
            margin-bottom: 20px;
        }
    }
    &-point-summary{
        font-size: 15px;
        margin-bottom: 40px;
        li{
            display: inline-block;
            margin-right: 30px;
            &:last-child{
                margin-right: 0;
            }
            span{
                color: #DB002F;
            }
        }
    }
    &-point-menu{
        border-top: 1px dashed #000;
        padding: 20px 0;
        li{
            padding: 20px 0 20px 60px;
            display: flex;
            flex-wrap: wrap;
        }
        &__radio{
            margin-right: 20px;
            padding: 5px 0;
            input{
                display: none;
                &:checked{
                    & + label{
                        &:after{
                            position: absolute;
                            content: "";
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background: #DB002F;
                            top: 5px;
                            left: -55px;
                        }
                    }
                }
            }
            label{
                font-size: 20px;
                position: relative;
                cursor: pointer;
                &:before{
                    position: absolute;
                    content: "";
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    border: 1px solid #C3C3C3;
                    top: 0;
                    left: -60px;
                }
            }
        }
        &__input{
            padding: 5px 0;
            input{
                margin-right: 5px;
                border: 1px solid #C3C3C3;
                border-radius: 3px;
                width: 100px;
                height: 30px;
                // box-shadow: 0 1px 2px rgba(#000, .2);
                padding: 4px 5px;
            }
        }
    }
    &-method-menu{
        padding: 20px 0;
        margin-bottom: 40px;
        border-top: 1px dashed #000;
        &:last-of-type{
            margin-bottom: 0;
        }
        li{
            padding: 20px 0 20px 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &--sub{
            border-top: none;
            padding: 0 0;
            li{
                border-bottom: none;
                padding: 5px 0 5px 40px;
            }
        }
        &__radio{
            margin-right: 20px;
            padding: 5px 0;
            input{
                display: none;
                &:checked{
                    & + label{
                        &:after{
                            position: absolute;
                            content: "";
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background: #DB002F;
                            top: 5px;
                            left: -55px;
                        }
                    }
                }
            }
            label{
                font-size: 20px;
                position: relative;
                cursor: pointer;
                &:before{
                    position: absolute;
                    content: "";
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    border: 1px solid #C3C3C3;
                    top: 0;
                    left: -60px;
                }
                small{
                    font-size: 15px;
                    display: block;
                    line-height: 1.5;
                    margin-top: 5px;
                }
            }
            &__txt{
                margin: 10px 0 0;
            }
            &--sm{
                input{
                    &:checked{
                        & + label{
                            &:after{
                                left: -35px;
                            }
                        }
                    }
                }
                label{
                    &:before{
                        left: -40px;
                        background: #fff;
                    }
                }
            }
        }
        &__img{
            padding: 5px 0;
        }
        &__description{
            width: 100%;
            display: none;
            &__inner{
                margin-top: 15px;
                background: #E9EAEE;
                padding: 15px 20px;
            }
            p{
                margin-bottom: 15px;
            }
        }
    }
    &-confirm{
        font-size: 16px;
        margin-bottom: 60px;
        border-bottom: 1px solid #000;
        dl{
            margin: 0;
            padding: 20px 0;
            display: flex;
            border-top: 1px solid #000;
            dt{
                width: 8em;
            }
            dd{
                width: calc(100% - 8em);
                margin: 0;
            }
        }
    }
}
