.pagination{
    &-nav{
        padding: 3px 0;
        margin-bottom: 8px;
    }
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 -2px 8px;
    li{
        margin: 0 2px 4px;
    }
    &__total{
        padding: 3px 8px;
    }
    &__first{
        border: 1px solid #A5A5A5;
        border-radius: 3px;
        a{
            display: block;
            padding: 3px 0;
            width: 21px;
            height: 25px;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: 10px;
                left: 11px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-left: 1px solid #000;
                transform: rotate(-45deg);
            }
            &:after{
                content: "";
                position: absolute;
                top: 10px;
                left: 7px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-left: 1px solid #000;
                transform: rotate(-45deg);
            }
        }
    }
    &__prev{
        border: 1px solid #A5A5A5;
        border-radius: 3px;
        a{
            display: block;
            padding: 3px 8px 3px 18px;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: 10px;
                left: 10px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-left: 1px solid #000;
                transform: rotate(-45deg);
            }
        }
    }
    &__num{
        border: 1px solid #A5A5A5;
        border-radius: 3px;
        a{
            display: block;
            padding: 3px;
            min-width: 20px;
            text-align: center;
        }
        &--current{
            padding: 3px;
            min-width: 22px;
            text-align: center;
            color: #fff;
            background: #0067B2;
        }
    }
    &__next{
        border: 1px solid #A5A5A5;
        border-radius: 3px;
        a{
            display: block;
            padding: 3px 18px 3px 8px;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: 10px;
                right: 10px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                transform: rotate(45deg);
            }
        }
    }
    &__last{
        border: 1px solid #A5A5A5;
        border-radius: 3px;
        a{
            display: block;
            padding: 3px 0;
            width: 21px;
            height: 25px;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: 10px;
                right: 11px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                transform: rotate(45deg);
            }
            &:after{
                content: "";
                position: absolute;
                top: 10px;
                right: 7px;
                width: 6px;
                height: 6px;
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                transform: rotate(45deg);
            }
        }
    }
}
