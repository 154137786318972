.footer{
    position: relative;
    @include media-breakpoint-down(md){
        margin-top: 40px;
        padding-bottom: 50px;
    }
    &__twitter{
        display: none;
        @include media-breakpoint-down(md){
            display: block;
            text-align: center;
            margin: 20px 0 50px;
        }
    }
    &__news{
        display: none;
        @include media-breakpoint-down(md){
            display: block;
            padding: 25px 15px;
            margin-bottom: 25px;
            border-top: 1px solid #C3C3C3;
        }
        &__ttl{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        &__list{
            &__item{
                margin-bottom: 15px;
                &__link{
                    &:hover{
                        .footer__news__list__item__txt{
                            text-decoration: underline;
                        }
                    }
                }
                &__date{
                    color: #838383;
                }
                &__txt{
                    margin-bottom: 0;
                }
            }
        }
        &__bottom{
            text-align: right;
            &__link{
                color: #0067B2;
                &:hover{
                    color: #0067B2;
                    text-decoration: underline;
                }
            }
        }
    }
    &__page-top{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 3px 3px 0 0;
        background: #DB002F;
        top: -40px;
        right: 30px;
        @include media-breakpoint-down(md){
            position: relative;
            display: block;
            top: auto;
            right: auto;
            margin-right: 15px;
            margin-left: auto;
        }
        &:after{
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -3px)rotate(-45deg);
        }
    }
    &__main{
        background: #E9EAEE;
        padding: 55px 0 45px;
        @include media-breakpoint-down(md){
            background: none;
            padding: 0;
        }
    }
    &__container{
        margin: 0 auto;
        max-width: 1110px;
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(xl){
            display: block;
            max-width: 600px;
        }
        @include media-breakpoint-down(md){
            max-width: none;
        }
    }
    &__menu{
        display: flex;
        width: 55%;
        @include media-breakpoint-down(xl){
            width: 100%;
            margin-bottom: 30px;
        }
        @include media-breakpoint-down(md){
            display: none;
        }
        &__block{
            width: 20%;
            padding: 0 15px;
        }
        &__ttl{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__list{
            li{
                margin-bottom: 8px;
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__sub-menu{
        display: flex;
        padding-left: 15px;
        @include media-breakpoint-down(xl){
            padding-left: 0;
        }
        @include media-breakpoint-down(md){
            display: block;
        }
        &__block{
            padding: 0 15px;
            @include media-breakpoint-down(md){
                padding: 0;
            }
        }
        &__item{
            margin-bottom: 6px;
            @include media-breakpoint-down(md){
                margin-bottom: 1px;
            }
        }
        &__ttl{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 3px;
            @include media-breakpoint-down(md){
                background: #E9EAEE;
                margin-bottom: 0;
                padding: 13px 15px;
            }
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__list{
            margin-bottom: 15px;
            @include media-breakpoint-down(md){
                padding: 9px 15px;
                margin-bottom: 0;
            }
            li{
                @include media-breakpoint-down(md){
                    padding: 5px 0;
                }
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__copyright{
        font-weight: bold;
        color: #fff;
        background: #000;
        text-align: center;
        padding: 16px 15px;
        margin-bottom: 0;
    }
}

.buy-footer{
    position: relative;
    @include media-breakpoint-down(md){
        margin-top: 40px;
    }
    &__page-top{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 3px 3px 0 0;
        background: #DB002F;
        top: -40px;
        right: 30px;
        @include media-breakpoint-down(md){
            position: relative;
            display: block;
            top: auto;
            right: auto;
            margin-right: 15px;
            margin-left: auto;
        }
        &:after{
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -3px)rotate(-45deg);
        }
    }
    &__main{
        background: #E9EAEE;
        padding: 55px 0 45px;
        @include media-breakpoint-down(md){
            background: none;
            padding: 0;
        }
    }
    &__container{
        margin: 0 auto;
        max-width: 1110px;
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(xl){
            display: block;
            max-width: 600px;
        }
        @include media-breakpoint-down(md){
            max-width: none;
        }
    }
    &__menu{
        display: flex;
        width: 55%;
        @include media-breakpoint-down(xl){
            width: 100%;
            margin-bottom: 30px;
        }
        @include media-breakpoint-down(md){
            display: none;
        }
        &__block{
            width: 20%;
            padding: 0 15px;
        }
        &__ttl{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__list{
            li{
                margin-bottom: 8px;
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__sub-menu{
        display: flex;
        padding-left: 15px;
        @include media-breakpoint-down(xl){
            padding-left: 0;
        }
        @include media-breakpoint-down(md){
            display: block;
        }
        &__block{
            padding: 0 15px;
            @include media-breakpoint-down(md){
                padding: 0;
            }
        }
        &__item{
            margin-bottom: 6px;
            @include media-breakpoint-down(md){
                margin-bottom: 1px;
            }
        }
        &__ttl{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 3px;
            @include media-breakpoint-down(md){
                background: #E9EAEE;
                margin-bottom: 0;
                padding: 13px 15px;
            }
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__list{
            margin-bottom: 15px;
            @include media-breakpoint-down(md){
                padding: 9px 15px;
                margin-bottom: 0;
            }
            li{
                @include media-breakpoint-down(md){
                    padding: 5px 0;
                }
                a{
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__copyright{
        font-weight: bold;
        color: #fff;
        background: #000;
        text-align: center;
        padding: 16px 15px;
        margin-bottom: 0;
    }
}
.login-footer{
    position: relative;
    @include media-breakpoint-down(md){
        margin-top: 40px;
    }
    &__page-top{
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 3px 3px 0 0;
        background: #DB002F;
        top: -40px;
        right: 30px;
        @include media-breakpoint-down(md){
            position: relative;
            display: block;
            top: auto;
            right: auto;
            margin-right: 15px;
            margin-left: auto;
        }
        &:after{
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -3px)rotate(-45deg);
        }
    }
    &__copyright{
        font-weight: bold;
        color: #fff;
        background: #000;
        text-align: center;
        padding: 16px 15px;
        margin-bottom: 0;
    }
}
