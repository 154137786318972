.support{
    &__section{
        margin: 30px 0;
        @include media-breakpoint-down(md){

        }
        &__ttl{
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 25px;
            @include media-breakpoint-down(md){
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    &__contact{
        max-width: 980px;
        margin: 0 auto;
        &__txt{
            font-size: 18px;
            text-align: center;
            margin: 40px 0;
            @include media-breakpoint-down(md){
                font-size: 15px;
                text-align: left;
            }
        }
        &__table{
            width: 100%;
            margin-bottom: 60px;
            tr{
                border-bottom: 1px solid #B2B2B2;
                th{
                    font-weight: normal;
                    vertical-align: top;
                    font-size: 18px;
                    padding: 30px 0;
                    width: 250px;
                    @include media-breakpoint-down(md){
                        width: 100%;
                        display: block;
                        padding: 15px 0;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
                td{
                    padding: 30px 0;
                    font-size: 18px;
                    @include media-breakpoint-down(md){
                        width: 100%;
                        display: block;
                        padding: 0 0 15px;
                        font-size: 15px;
                    }
                }
            }
        }
        &__error{
            font-size: 18px;
            color: #FF0000;
            margin-bottom: 0;
            position: relative;
            padding-left: 30px;
            padding-bottom: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
                padding-left: 24px;
            }
            img{
                position: absolute;
                top: 2px;
                left: 0;
                @include media-breakpoint-down(md){
                    width: 18px;
                }
            }
        }
        &__input{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            max-width: 400px;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__select{
            width: 100%;
            font-size: 16px;
            max-width: 400px;
            appearance: none;
            border: 1px solid #919191;
            border-radius: 5px;
            padding: 10px;
            background: none;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__textarea{
            display: block;
            border: 1px solid #919191;
            border-radius: 5px;
            width: 100%;
            max-width: 800px;
            font-size: 16px;
            padding: 10px;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        &__btn{
            text-align: center;
            display: block;
            font-size: 22px;
            font-weight: bold;
            background: #0067B2;
            border-radius: 17px;
            color: #fff;
            padding: 10px;
            max-width: 380px;
            width: 100%;
            margin: 40px auto 0;
            @include media-breakpoint-down(md){
                font-size: 18px;
            }
            &:hover{
                color: #fff;
            }
        }
    }
}
