.timer {
  padding: 0 7px;
  position: relative;
  line-height: 1;
  &:after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 1px;
    height: 13px;
    background: #fff;
    transform: rotate(20deg);
  }
}
.duration {
  padding: 0 7px;
  line-height: 1;
}

/* Controls */
.controlsOuter {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 3%;
}
.controlsInner {
  display: flex;
  // position: absolute;
  // width: 340px;
  // height: 70px;
  // left: 50%;
  // margin: 0 -170px;
}
.btn {
  // position: absolute;
  cursor: pointer;
  opacity: 0.9;
  -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.33));
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.33));
  -webkit-user-select: none;
  user-select: none;
}
.btn:hover {
  opacity: 1;
}

.playBtn {
  background-image: url(../images/icon-player-play.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 5px;
}
.pauseBtn {
  background-image: url(../images/icon-player-pause.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  display: none;
  margin: 0 5px;
}
.prevBtn {
  background-image: url(../images/icon-player-prev.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 5px;
}
.nextBtn {
  background-image: url(../images/icon-player-next.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  margin: 0 5px;
}
.volumeBtn {
  background-image: url(../images/icon-player-volume.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  position: absolute;
  top: 10px;
  right: 10px;
}

.track{
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  max-width: calc(50% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 20px;
  &:before{
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-image: url(../images/icon-player-music.svg);
    background-size: contain;
  }
}

/* Progress */
.waveform {
  background: #F2F2F2;
  position: relative;
  width: 100%;
  height: 8px;
  // position: absolute;
  // left: 0;
  // top: 50%;
  // margin: -15% auto;
  /* display: none; */
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
// .bar {
//   position: absolute;
//   top: 50%;
//   left: 0;
//   width: 100%;
//   height: 2px;
//   background-color: rgba(255, 255, 255, 0.9);
//   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
//   opacity: 0.9;
// }
.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 8px;
  background-color: #DB002F;
  pointer-events: none;
}

/* Loading */
.loading {
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
  display: none;
}
@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/* Plylist */
.playlist {
  height: 111px;
  padding-right: 12px;
}
.list {
  // height: 110px;
  // overflow: auto;
}
.list-song {
  line-height: 13px;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &.nowplaying{
    color: #FFC107;
  }
  &:hover{
    background-color: rgba(#fff, .2);
  }
}
.volume {
  width: 60px;
  height: 4px;
  max-width: calc(50% - 90px);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  touch-action: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: none;
  @media (hover: hover) and (pointer: fine) {
    display: block;
  }
  &Btn{
    top: 0;
    left: 0;
    position: absolute;
    background-image: url(../images/icon-player-volume.svg);
    width: 16px;
    height: 16px;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    cursor: pointer;
    &.zero{
      background-image: url(../images/icon-player-volume-0.svg);
    }
  }
}
.bar {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
}
.barEmpty {
  width: 100%;
  opacity: 0.5;
  box-shadow: none;
  cursor: pointer;
}
.barFull {
  width: 100%;
}
.sliderBtn {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  left: 100%;
  transform: translateX(-5px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.33);
  border-radius: 25px;
  cursor: pointer;
}

/* Fade-In */
.fadeout {
  webkit-animation: fadeout 0.5s;
  -ms-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}
.fadein {
  webkit-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  animation: fadein 0.5s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.audioPlayer{
  width: 460px;
  max-width: 100%;
  background: #606060;
  color: #fff;
  text-align: left;
  &__ttl{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__main{
    display: flex;
    padding: 15px;
    // border: 4px solid #000;
    &__left{
      width: 113px;
      padding-right: 10px;
    }
    &__right{
      width: calc(100% - 113px);
    }
  }
  &__control{
    background: #000;
    &__top{
      display: flex;
    }
    &__holder{
      position: relative;
      width: calc(100% - 100px);
      margin: 10px 0 10px 10px;
    }
    &__timer{
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__bottom{
      // background: #606060;
      border-top: 1px solid #606060;
      background: #000;
      padding: 10px;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
}

.swiper{
  &-scrollbar{
    background-color: rgba(255, 255, 255, 0.2);
    &-drag{
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
