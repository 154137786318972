.header{
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 15px;
        border-bottom: 1px solid #C3C3C3;
        position: relative;
        height: 53px;
    }
    &__logo{
        display: flex;
        align-items: center;
        a{
            line-height: 1;
            @include media-breakpoint-down(md){
                width: 114px;
            }
        }
        span{
            line-height: 1;
            white-space: nowrap;
            font-size: 20px;
            font-weight: bold;
            margin-left: 10px;
            @include media-breakpoint-down(md){
                font-size: 13px;
            }
        }
    }
    &__main{
        display: flex;
        margin-left: 10px;
        @include media-breakpoint-down(md){
            margin-left: 0;
        }
    }
    &__search-form{
        @include media-breakpoint-up(lg){
            display: block !important;
        }
        @include media-breakpoint-down(lg){
            position: absolute;
            background: #fff;
            z-index: 2;
            top: 100%;
            left: 0;
            right: 0;
            padding: 13px 15px;
            border-top: 1px solid #C3C3C3;
            border-bottom: 1px solid #C3C3C3;
            display: none;
        }
        &__container{
            display: inline-flex;
            border: 1px solid #D0D0D0;
            overflow: hidden;
            border-radius: 15px;
            width: 100%;
        }
        &__category{
            position: relative;
            span{
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + 1px);
                height: 100%;
                background: #E9EAEE;
                line-height: 1;
                padding: 7px 10px;
                pointer-events: none;
            }
            &:after{
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 3px 5px 3px;
                border-color: #000 transparent transparent transparent;
                top: 50%;
                margin-top: -3px;
                right: 10px;
                pointer-events: none;
            }
            select{
                border: none;
                height: 100%;
                padding: 0 10px;
                font-size: 16px;
                width: 144px;
            }
        }
        &__txt{
            width: 100%;
            input{
                font-size: 16px;
                height: 28px;
                width: 220px;
                padding: 4px 8px;
                border: none;
                @include media-breakpoint-down(lg){
                    width: 100%;
                }
            }
        }
        &__btn{
            border: none;
            padding: 6px 13px 7px 10px;
            min-width: 36px;
            line-height: 1;
            background: #DB002F;
        }
    }
    &__nav{
        display: flex;
        margin-left: 20px;
        &__item{
            padding: 0 8px;
            @include media-breakpoint-down(md){
                display: none;
            }
            &--sp{
                @include media-breakpoint-down(md){
                    display: block;
                    padding: 0 0 0 16px;
                }
            }
            &--search{
                display: none;
                @include media-breakpoint-down(lg){
                    display: block;
                    margin-top: 1px;
                }
                @include media-breakpoint-down(md){
                    margin-top: 0;
                }
            }
            button{
                background: none;
                width: auto;
                border: none;
                padding: 0;
            }
        }
        &__btn{
            position: relative;
            &--active{
                .header__nav__btn__main-item{
                    opacity: 0;
                }
                .header__nav__btn__red-item{
                    opacity: 1;
                }
            }
            &__red-item{
                 position: absolute;
                 top: 0;
                 left: 0;
                 opacity: 0;
            }
        }
    }
    &__menu{
        display: none;
        @include media-breakpoint-down(md){
            display: flex;
            border-bottom: 1px solid #C3C3C3;
        }
        &__item{
            width: 20%;
            border-right: 1px solid #C3C3C3;
            &:last-child{
                border-right: none;
            }
            a{
                display: flex;
                height: 56px;
                justify-content: center;
                align-items: center;
                img{
                    &:last-child{
                        display: none;
                    }
                }
            }
            &--active{
                a{
                    background: #DB002F;
                    img{
                        &:first-child{
                            display: none;
                        }
                        &:last-child{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.buy-header{
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 15px;
        border-bottom: 1px solid #C3C3C3;
        position: relative;
        height: 53px;
    }
    &__logo{
        @include media-breakpoint-down(md){
            width: 114px;
        }
    }
    &__nav{
        display: flex;
        margin-left: 20px;
        @include media-breakpoint-down(sm){
            margin-right: -12px;
        }
        &__item{
            padding: 0 12px;
        }
    }
}

.login-header{
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 15px;
        border-bottom: 1px solid #C3C3C3;
        height: 53px;
    }
    &__logo{
        @include media-breakpoint-down(md){
            width: 114px;
        }
    }
}
